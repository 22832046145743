
.App {
  /* text-align: center; */
  /* background-color: #ffffff; */
  background-color: rgb(231, 231, 231);
  min-height: 100vh;
  margin-top: 95px;
  /* transform: rotate(90deg); */
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


input:focus {
  outline: none;
  box-shadow: none;
  border-color: inherit;
}

body.no-scroll {
  overflow: hidden;
}
.row-general-tips{
  margin-top: 70px;
}

