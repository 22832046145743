.div-footer-modal-background{
    background-color: #3636368f;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: flex-end;
    align-items: center;
    position: fixed;
    top: 0; 
    left: 0;
    overflow-y: hidden;
    flex-direction: column;
    /* animation-name: inherit; */
    /* animation-duration: 0.2s; */
}

.div-footer-modal{

  display: flex;
  background-color: #fefefe;
  border: 1px solid #888;
  width: 100%;
  min-height: 100%;
  /* transition: padding-bottom 0.3s; */
  /* animation-name: none;
  animation-duration: 0.0s; */
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  /* animation-timing-function: ease-out; */
  flex-direction: column;
  /* justify-content: center; */
  /* align-items: center; */
}

.div-footer-modal-header{
  display: flex;
  flex-direction: row;
  width: 100%;
  height: fit-content;
  justify-content: center;
  align-items: flex-start;
  margin-top: 30px;
  /* background-color: #0c0c0c42; */
}

.div-footer-modal-header-description {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: fit-content;
}
.div-footer-modal-header-icon{
  display: flex;
  width: 50px;
  height: fit-content;
  /* background-color: #78020270; */
  justify-content: center;
  /* align-items: center; */
}

.footer-modal-header-icon{
  font-size: 25px;
  color: rgb(55, 55, 55);
  /* margin-bottom: 10px; */
}

.div-footer-modal-header-mid{
  display: flex;
  flex-direction: column;
  width: 70%;
  height: 100%;
  /* background-color: #0a027870; */
}

.footer-modal-header-title {
  display: flex;
  color: #666666;
  font-family: 'Nunito-Bold';
  font-size: 21px;
  text-align: center;
}

.footer-modal-header-description {
  display: flex;
  color: #666666;
  font-family: 'Nunito-Regular';
  font-size: 16px;
  text-align: left;
  margin-right: 5px;
}

.div-footer-modal-header-close-icon{
  display: flex;
  width: 50px;
  height: fit-content;
  justify-content: center;
  /* background-color: #1a780275; */
}

.footer-modal-header-close-icon{
  font-size: 25px;
  color: rgb(55, 55, 55);
  /* margin-bottom: 10px; */
}

.div-footer-modal-mid{
  display: flex;
  /* flex: 0.8; */
  justify-content: center;
  align-items: center;
  height: auto;
  margin-top: 10px;
  /* background-color: #83cc0d8b; */
}

.div-footer-modal-bootom{
  display: flex;
  /* flex: 0.3; */
  /* position: fixed; */
  flex-direction: row;
  width: 100%;
  height: 80px;
  /* background-color: #cc0da942; */
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  /* bottom: 0; */
  
  /* padding-left: 10px; */
  /* padding-right: 10px; */

  /* align-items: flex-start; */
  /* margin-top: 30px;
  margin-bottom: 30px; */
}

@keyframes slideIn {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}


@media (max-width: 440px) {
  
}


