header {
  display: flex;
  background: #ffffff;
  color: #838383;
  justify-content: center;
  align-items: center;
  height: 120px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 20; 
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

header.hidden {
  transform: translateY(-140px);
  transition: transform 0.3s ease-in-out;
}

header.show {
  transform: translateY(0px);
  transition: transform 0.2s ease-in-out;
}

.header-content{
  display: flex;
  height: 100%;
  min-width: 750px;  
}

.div-options{
  display: flex;
  flex-direction: row;
  height: 100%;
  min-width: 800px;
}

@media (max-width: 1054px) {  
  header {
    height: 95px;
    background: #ffffff;
  }

  .div-options{
    display: none;
  }

  .header-content{
    justify-content: center;
  }
}
