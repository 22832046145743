.main-mobile-banner {
  position: relative;
  height: 100%;
  max-width: 380px;
}

.main-mobile-banner .content-mobile-banner {
  background-color: #ffffff;
  color: #35bde8;
  border: none;
  display: flex;
  border-radius: 16px;
  font-family: 'Nunito-Regular';
  font-size: 20px;
}

.main-mobile-banner .content-mobile-banner .description-mobile-banner{
  text-align: left;
  margin-left: 20px;
  margin-top: 20px;
  height: 200px;
  width: 50%;
}

.main-mobile-banner .content-mobile-banner  img {
  max-height: 180px;
  height: 100%;
  right: -50px;
  top: 20px;
  position: absolute;
}

.main-mobile-banner button {
  background-color: #f9cb17;
  color: #000000;
  border: none;
  display: flex;
  border-radius: 16px;
  font-family: 'Nunito-bold';
  text-transform: uppercase;
  font-size: 20px;
  width: 100%;
  padding: 10px;
  justify-content: center;
  position: absolute;
  top: 190px;
}

@media (max-width: 420px) {
  .main-mobile-banner .content-mobile-banner {
    display: flex;
    flex-direction: column;
  }

  .main-mobile-banner .content-mobile-banner .description-mobile-banner{
    width: 85%;
    height: auto;
    margin-top: 10px;
  }

  .main-mobile-banner .content-mobile-banner  img {
    max-height: 140px;
    position: relative;
    margin-top: -35px;
    margin-bottom: 55px;
    right: -70px;
    width: fit-content;
  }

  .main-mobile-banner button {
    font-size: 15px;
    top: 256px;
  }
}