
.div-skeleton {
  display: flex;
  opacity: .7;
  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(0, 0%, 57%);
  }

  100% {
    background-color: hsl(0, 0%, 83%);
  }
}

@media (max-width: 1054px) {

}




