body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  overflow-y: scroll;
 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* NunitoSans_7pt-Regular.ttf */
/* Caminho relativo para a pasta de fontes */

@font-face {
  font-family: 'Nunito-Bold';
  src: url('./Fonts//NunitoSans/static/corrects/Nunito-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Nunito-Regular';
  src: url('./Fonts//NunitoSans/static/corrects/Nunito-Regular.ttf') format('truetype');
  font-style: normal; 
}

@font-face {
  font-family: 'Nunito-Italic';
  src: url('./Fonts//NunitoSans/static/corrects/Nunito-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'Nunito-Italic';
  src: url('./Fonts//NunitoSans/static/corrects/Nunito-Italic.ttf') format('truetype');
}

.DayPicker-Day--selected {
  border-radius: 0 !important; /* Desabilita o arredondamento */
}

/* Estilo para os cantos da primeira e última data selecionada */
.DayPicker-Day--selected:first-child {
  border-top-left-radius: 5px !important; /* Ajuste para o canto superior esquerdo */
  border-bottom-left-radius: 5px !important; /* Ajuste para o canto inferior esquerdo */
}

.DayPicker-Day--selected:last-child {
  border-top-right-radius: 5px !important; /* Ajuste para o canto superior direito */
  border-bottom-right-radius: 5px !important; /* Ajuste para o canto inferior direito */
}

.div-main{
  background-color: rgba(217,217,217,1);
  z-index: -1; 
}

.div-main-gradient {
  position: absolute;
  top: 0; 
  left: 0;
  width: 100%;
  height: 460px; 
  z-index: 0; 
  background: linear-gradient(180deg, rgba(8,77,102,1) 46%, rgba(0,110,149,1) 68%, rgba(217,217,217,1) 100%);
}

@media (max-width: 1054px) {
  .div-main{
    background-color: rgba(0,104,141,1);
    z-index: -1;
  }

  .div-main-gradient {
    display: none;
  }

}

* {
  /* -webkit-user-select: none; 
  -moz-user-select: none;    
  -ms-user-select: none;     
  user-select: none;         */

  /* -webkit-overflow-scrolling: touch; */
}

/*----------------*/
/* @font-face {
  font-family: 'Nunito-SemiBold';
  src: url('./Fonts//NunitoSans/static/NunitoSans_7pt-SemiBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
} */

/* @font-face {
  font-family: 'Nunito-Regular';
  src: url('./Fonts//NunitoSans/static/NunitoSans_7pt-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
} */

/* @font-face {
  font-family: 'Nunito';
  src: url('./Fonts//NunitoSans/static/NunitoSans_7pt-Medium.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
} */

