.div-switch{
  /* background-color: #ffffff;       */
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: 35px;
  border-radius: 5px;
}

.switch-button-option1{
  /* background-color: rgb(255, 255, 255);      */
  width: 50%;
  height: 100%;
  border-radius: 10px;
  border-bottom-left-radius: 10px;
  font-size: 14px;
  color: rgb(64, 64, 64);
  cursor: pointer;
  border: 0px solid #3d3d3d7c ;
  justify-content: center;
  align-items: center;
  padding-top: 3px;
  border: 1px solid #ffffff;
  margin-right: 5px;

  &:active{
    background-color: #1dc2ec;
  }
}

.switch-button-option2{
  /* background-color: rgb(255, 255, 255);      */
  width: 50%;
  height: 100%;
  border-radius: 10px;
  border-bottom-right-radius: 10px;
  font-size: 14px;
  color: rgb(64, 64, 64);
  cursor: pointer;
  border: 0px solid #3d3d3d7c ;
  justify-content: center;
  align-items: center;
  padding-top: 3px;
  border: 1px solid #ffffff;


  &:active{
    background-color: #1dc2ec;
  }
}

.div-text-count {
  display: flex;
  width: 30px;
  justify-content: center;
  align-items: center;
}

.text-switch-button{
  /* color: rgb(255, 255, 255); */
  font-size: 15px;
  font-family: 'Nunito-Regular';
  font-weight: 600;
}

@media (max-width: 1054px) {
  .div-switch{
    /* background-color: #ffffff;       */
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    border-radius: 5px;
  }
}


@media (max-width: 580px) {
  .div-count-button{
    /* background-color: #ffffff;       */
    display: flex;
    flex-direction: row;
    width: 20px;
    justify-content: center;
    align-items: center;
    height: 30px;
    border-radius: 5px;
  }
  
  .count-button{
    background-color: rgb(255, 255, 255);     
    width: 25px;
    height: 25px;
    border-radius: 5px;
    font-size: 14px;
    color: rgb(64, 64, 64);
    cursor: pointer;
    border: 0px solid #3d3d3d7c ;
    justify-content: center;
    align-items: center;
    padding-top: 3px;
    /* padding-right: 8px; */
  
    &:active{
      background-color: #1dc2ec;
    }
  }
  
  .div-text-count {
    display: flex;
    width: 30px;
    justify-content: center;
    align-items: center;
  }
  
  .text-count{
    color: rgb(255, 255, 255);
    font-size: 20px;
    font-family: 'Nunito-Regular';
    margin-left: 15px;
    margin-right: 15px;
  }
}
