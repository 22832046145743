.div-inlineBanner {
  display: flex;
  background-color: #ECECEC;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: 120px;
  /* border: 1px solid #3d3d3d; */
  border-radius: 10px;
  flex-direction: row;
  padding-left: 0px;
  padding-right: 10px;
}

.div-inlineBanner-image-content {
  background-color: #ECECEC;
  display: flex;
  width: 80%;
  height: 100%;
  align-items: center;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.div-inlineBanner-image {
  background-color: #FFFFFF;
  /* background-color: #01A9D7; */
  display: flex;
  width: 25%;
  justify-content: center;
  align-items: center;
  height: 100%;
  /* border: 1px solid #3d3d3d7c ; */
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.div-inlineBanner-content {
  display: flex;
  /* background-color: rgb(13, 5, 246); */
  width: 55%;
  justify-content: center;
  align-items: center;
  height:70%;
  flex-direction: column;
  margin-left: 20px;
}

.div-inlineBanner-button {
  display: flex;
  /* background-color: rgb(246, 5, 130); */
  width: 20%;
  justify-content: center;
  align-items: center;
  padding-right: 20px;
  height: 80%;
}


.img-inlineBanner {
  width: 90%;
  object-fit: fill; 
}

.title-inlineBanner {
  display: flex;
  width: 100%;
  height: 20%;
}

.title-text-inlineBanner {
  color: #000000;
  font-weight: normal;
  font-size: 15px; 
   font-family: 'Nunito-Bold';
}

.desciption-inlineBanner {
  display: flex;
  /* background-color: rgb(5, 246, 194); */
  width: 100%;
  /* justify-content: center;
  align-items: center; */
  height: 60%;
}

.desciption-text-inlineBanner {
  color: #000000;
  font-weight: normal;
  font-size: 15px; 
   font-family: 'Nunito-Regular';
  margin-top: 3px;
}

.advertiser-inlineBanner {
  display: flex;
  /* background-color: rgba(5, 121, 246, 0.568); */
  width: 100%;
  /* justify-content: flex-end; */
  align-items: flex-end; 
  height: 20%;
}

.advertiser-text-inlineBanner {
  color: #686868;
  font-weight: normal;
  font-size: 11px; 
   font-family: 'Nunito-Regular';
}

.ulr-inlineBanner {
  display: flex;
  width: 100%;
  height: 80%; 
  color: aliceblue;
  background-color: rgb(0, 32, 135);
  cursor: pointer;
  border-radius: 5px;
  border: none;
  justify-content: center;
  align-items: center;
}

.ulr-text-inlineBanner {
  color: aliceblue;
  font-size: 15px;
  font-family: 'Nunito-Bold';
  font-weight: bold;
}



@media (max-width: 1054px) {
  .div-inlineBanner {
    display: flex;
    background-color: #ECECEC;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    height: 120px;
    border-radius: 10px;
    flex-direction: row;
  }

  .div-inlineBanner-button {
    display: flex;
    /* background-color: rgb(246, 5, 130); */
    width: 140px;
    justify-content: center;
    align-items: center;
    padding-right: 20px;
    height: 80%;
  }


  .ulr-text-inlineBanner {
    color: aliceblue;
    font-size: 12px;
    font-family: 'Nunito-Bold';
    font-weight: bold;
  }
}

@media (max-width: 665px) {
  .div-inlineBanner {
    display: flex;
    background-color: #ECECEC;
    width: 100%;
    height: fit-content;
    max-height: 140px;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    flex-direction: column;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .div-inlineBanner-image-content {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
  }

  .div-inlineBanner-image {
    display: flex;
    /* background-color: rgb(230, 246, 5); */
    width: 20%;
    min-width: 70px;
    justify-content: center;
    align-items: center;
    height: 80%;
    border: 1px solid #3d3d3d7c ;
    border-radius: 3px;
  }

  .div-inlineBanner-content {
    display: flex;
    /* background-color: rgb(13, 5, 246); */
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 80%;
    flex-direction: column;
    margin-left: 10px;
  }

  .div-inlineBanner-button {
    display: flex;
    /* background-color: rgb(246, 5, 130); */
    position: relative;
    width: 150px;
    justify-content: flex-end;
    align-items: center;
    align-self: flex-end;
    padding-right: 0;
    height: 60%;
    margin-top: 10px;
    margin-right: 10px;
  }

  .ulr-inlineBanner {
    display: flex;
    width: 120px;
    height: 40px; 
    color: aliceblue;
    background-color: rgb(0, 32, 135);
    cursor: pointer;
    border-radius: 5px;
    border: none;
    justify-content: center;
    align-items: center;
  }
}



@media (max-width: 360px) {
  .div-inlineBanner {
    display: flex;
    background-color: #ECECEC;
    width: 100%;
    height: fit-content;
    max-height: none;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    flex-direction: column;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .div-inlineBanner-image-content {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
  }

  .div-inlineBanner-image {
    display: flex;
    /* background-color: rgb(230, 246, 5); */
    width: 20%;
    min-width: 70px;
    justify-content: center;
    align-items: center;
    height: 80%;
    border: 1px solid #3d3d3d7c ;
    border-radius: 3px;
  }

  .div-inlineBanner-content {
    display: flex;
    /* background-color: rgb(13, 5, 246); */
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 80%;
    flex-direction: column;
    margin-left: 10px;
  }

  .div-inlineBanner-button {
    display: flex;
    /* background-color: rgb(246, 5, 130); */
    width: 120px;
    justify-content: flex-end;
    align-items: center;
    height: 60%;
    margin-top: 10px;
  }

  .ulr-inlineBanner {
    display: flex;
    width: 120px;
    height: 40px; 
    color: aliceblue;
    background-color: rgb(0, 32, 135);
    cursor: pointer;
    border-radius: 5px;
    border: none;
    justify-content: center;
    align-items: center;
  }
}


