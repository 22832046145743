/*REMOVE*/
/* .div-trip-type-switch{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 180px;
  height: 60px; 
  margin-bottom: 10px;
} */

.div-trip-type-switch{
  background-color: #e1e1e1;
  border: 0px solid #e1e1e1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* min-width: 300px; */
  height: 70px; 
  margin-top: 0px;
  margin-bottom: 0px;
}

.div-one-way-only-show{
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  border: 0px solid transparent;
  width: 50%;
  height: 100%;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  justify-content: center;
  cursor: pointer;
  border-right: 2px solid rgba(0, 104, 141, 1);
}

.div-road-trip-only-show{
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  border: 0px solid transparent;
  width: 50%;
  height: 100%;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  justify-content: center;
  cursor: pointer;
}


.div-road-trip-disable{
  background-color: #DDDDDD;
  display: flex;
  flex-direction: column;
  border: 0px solid transparent;
  border-left: 0px solid #e1e1e1;
  width: 50%;
  height: 100%;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  justify-content: center;
}

.div-one-way-selected{
  /* background-color: #036f96; */
  display: flex;
  flex-direction: column;
  border: 2px solid #036f96;
  width: 50%;
  height: 100%;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  justify-content: center;
}

.div-one-way-unselected{
  /* background-color: #e1e1e1; */
  display: flex;
  flex-direction: column;
  border: 2px solid transparent;
  width: 50%;
  height: 100%;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  justify-content: center;
}


.div-road-trip-selected{
  /* background-color: #036f96; */
  display: flex;
  flex-direction: column;
  border: 2px solid #036f96;
  width: 50%;
  height: 100%;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  justify-content: center;
}

.div-road-trip-unselected{
  display: flex;
  flex-direction: column;
  border: 2px solid transparent;
  width: 50%;
  height: 100%;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  justify-content: center;
}

.div-trip-type-switch-title{
  /* background-color: #03960f; */
  display: flex;
  height: 30%;
}

.div-trip-type-switch-content{
  /* background-color: #032096; */
  display: flex;
  width: 100%;
  height: 70%;
  align-items: center;
  justify-content: center;
  padding-left: 2px;
  /* padding-top: 5px; */
  /* padding-right: 5px; */
 
}

.div-trip-type-switch-content-inner {
  width: 100%;
  height: 100%;
  display: flex;
  margin: 0 auto;
  /* justify-content: space-between; */
  align-items: center;
  gap: 5px;
  padding-left: 5px;
  padding-right: 0px;
}

.div-trip-type-switch-content-inner-empty {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
  padding-left: 10px;
}

.text-trip-type-switch-content-inner-empty {
  font-family: 'Nunito-Regular';
  font-size: 17px;
  color: #666666;
}

.div-trip-type-switch-content-inner-disable {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
  padding-left: 10px;
}

.text-trip-type-switch-content-inner-disable {
  font-family: 'Nunito-Regular';
  font-size: 17px;
  color: #F9F9F9;
}

.text-trip-type-switch-title{
  display: flex;
  font-family: 'Nunito-Bold';
  margin-left: 5px;
}

.departure-date-item{
  background-color: #00A9D6;
  border: 2px solid #00A9D6;
  display: flex;
  aspect-ratio: 1/1;
  height: 60%;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
}

.return-date-item{
  background-color: #00688D;
  border: 2px solid #00688D;
  display: flex;
  aspect-ratio: 1/1;
  height: 60%;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
}

.div-trip-type-switch-empty-data{
   /* background-color: #032096; */
  display: flex;
  aspect-ratio: 1/1;
  height: 60%;
  border: 2px dashed gray;
  border-radius: 5px;
}

.departure-date-item-day-left{
  /* background-color: #d62400;   */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* height: 50%; */
  margin-left: 1px;
  width: 80%;
}

.departure-date-item-day-right{
  /* background-color: #c4d600; */
  display: flex;
  height: 100%;
  width: 50%;
}

.departure-date-item-day{
  display: flex;
  color: aliceblue;
  font-family: 'Nunito-Bold';
  font-size: 12px;
  /* margin-top: 10px; */
}

.departure-date-item-month{
  display: flex;
  /* position: absolute; */
  color: aliceblue;
  font-family: 'Nunito-Bold';
  font-size: 11px;
  margin-top: -5px;

  /* text-transform: uppercase; */
}

.departure-date-item-day-right-icon{
  display: flex;
  color: aliceblue;
  width: 10px;
  height: 10px;
}



@media (max-width: 1055px) {
  .div-trip-type-switch{
    background-color: #ebebeb;
    border: 2px solid #e1e1e1;
    border-radius: 15px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 60px; 
    margin-top: 0px;
    margin-bottom: 20px;
  }
  
  .div-one-way-only-show{
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    border: 2px solid transparent;
    width: 50%;
    height: 100%;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    justify-content: center;
  }

  .div-road-trip-only-show{
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    border: 2px solid transparent;
    border-left: 2px solid #e1e1e1;
    width: 50%;
    height: 100%;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    justify-content: center;
  }

  .div-road-trip-disable{
    background-color: #DDDDDD;
    display: flex;
    flex-direction: column;
    border: 2px solid transparent;
    border-left: 2px solid #e1e1e1;
    width: 50%;
    height: 100%;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    justify-content: center;
  }

  .div-one-way-selected{
    /* background-color: #036f96; */
    display: flex;
    flex-direction: column;
    border: 2px solid #036f96;
    width: 50%;
    height: 100%;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    justify-content: center;
  }

  .div-one-way-unselected{
    /* background-color: #e1e1e1; */
    display: flex;
    flex-direction: column;
    border: 2px solid transparent;
    width: 50%;
    height: 100%;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    justify-content: center;
  }


  .div-road-trip-selected{
    /* background-color: #036f96; */
    display: flex;
    flex-direction: column;
    border: 2px solid #036f96;
    width: 50%;
    height: 100%;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    justify-content: center;
  }

  .div-road-trip-unselected{
    display: flex;
    flex-direction: column;
    border: 2px solid transparent;
    width: 50%;
    height: 100%;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    justify-content: center;
  }

  .div-trip-type-switch-title{
    /* background-color: #03960f; */
    display: flex;
    height: 30%;
  }

  .div-trip-type-switch-content{
    /* background-color: #032096; */
    display: flex;
    width: 100%;
    height: 70%;
    align-items: center;
    justify-content: center;
    /* padding-top: 5px; */
    /* padding-right: 5px; */
   
  }

  .div-trip-type-switch-content-inner {
    width: 100%;
    height: 100%;
    display: flex;
    margin: 0 auto;
    justify-content: space-between;
    align-items: center;
    gap: 1px;
    padding-left: 5px;
    padding-right: 5px;
  }

  .div-trip-type-switch-content-inner-empty {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
    padding-left: 10px;
  }

  .text-trip-type-switch-content-inner-empty {
    font-family: 'Nunito-Regular';
    font-size: 17px;
    color: #666666;
  }

  .div-trip-type-switch-content-inner-disable {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
    padding-left: 10px;
  }

  .text-trip-type-switch-content-inner-disable {
    font-family: 'Nunito-Regular';
    font-size: 17px;
    color: #F9F9F9;
  }

  .text-trip-type-switch-title{
    display: flex;
    font-family: 'Nunito-Bold';
    margin-left: 5px;
  }

  .departure-date-item{
    background-color: #00A9D6;
    border: 2px solid #00A9D6;
    display: flex;
    aspect-ratio: 1/1;
    height: 60%;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
  }

  .return-date-item{
    background-color: #00688D;
    border: 2px solid #00688D;
    display: flex;
    aspect-ratio: 1/1;
    height: 60%;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
  }

  .div-trip-type-switch-empty-data{
     /* background-color: #032096; */
    display: flex;
    aspect-ratio: 1/1;
    height: 60%;
    border: 2px dashed gray;
    border-radius: 5px;
  }

  .departure-date-item-day-left{
    /* background-color: #d62400;   */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* height: 50%; */
    margin-left: 1px;
    width: 80%;
  }

  .departure-date-item-day-right{
    /* background-color: #c4d600; */
    display: flex;
    height: 100%;
    width: 50%;
  }

  .departure-date-item-day{
    display: flex;
    color: aliceblue;
    font-family: 'Nunito-Bold';
    font-size: 12px;
    /* margin-top: 10px; */
  }

  .departure-date-item-month{
    display: flex;
    /* position: absolute; */
    color: aliceblue;
    font-family: 'Nunito-Bold';
    font-size: 11px;
    margin-top: -5px;

    /* text-transform: uppercase; */
  }

  .departure-date-item-day-right-icon{
    display: flex;
    color: aliceblue;
    width: 10px;
    height: 10px;
  }
}


