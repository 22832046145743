
.div-container-calendar{
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #ffffff;
  box-shadow: 0 0 0px rgba(0, 0, 0, 0.5);
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 10px;
}


.responsive-calendar {
  display: flex;
  /* background-color: #036f96; */
  /* box-shadow: none; */
  /* box-shadow: 0 0 5px rgba(0, 0, 0, 0.5); */
}
.div-calendar-footer{
  /* background-color: #f4f4f4; */
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 70px;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  /* align-self: center; */
}

.div-footer-button-calendar {
  /* background-color: #f4f4f4; */
  display: flex;
  position: relative;
  height: 70px;
  width: 90%;
  justify-content: center;
  align-items: center;
  padding-top: 2px;
  padding-bottom: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  /* margin-top: 20px; */
}
.div-calendar-footer-item{
  /* background-color: #133a66; */
  display: flex;
  flex-direction: column;
  width: 50px;
  height: 50px;
  border-radius: 5px;
  margin-inline: 10px;
  cursor: pointer;
  justify-content: center;
  align-items: center;

  &:hover{
    background-color: #ea7676;
  }
  /* align-self: center; */
}

.text-getsitecontrol-calendar{
  display: flex;
  color: #0992c3;
  font-size: 15px;
  font-family: 'Nunito-Regular';
  margin-bottom: 35px;
}
.text-mouth-calendar-footer-item{
  color: rgb(248, 248, 248);
  font-size: 16px;
   font-family: 'Nunito-Regular';
}

.text-day-calendar-footer-item{
  color: rgb(248, 248, 248);
  font-size: 16px;
   font-family: 'Nunito-Regular';
}
.text-nothing-calendar-footer {
  color: rgb(98, 98, 98);
  font-size: 16px;
  font-family: 'Nunito-Regular';
}

  .departure-dates-selected{
    border: 4px solid #0fbcf9;
    /* width: 35px;
    height: 35px; */
  }

  .retur-dates-selected{
    border: 3px solid #036f96;
    /* width: 35px;
    height: 35px; */
  }
  

.custom-today-day{
    /* width: 35px; */
    /* height: 35px;   */
    border-radius: 0;
}

.text-calendar-day {
  user-select: none;
}

.calendar-header-web {
  display: flex;
  width: 80%;
  height: 60px;
  border-bottom: 1px solid #DDDDDD;
}

.calendar-header-web-left {
  display: flex;
  width: 40%;
  height: 70px;
  align-items: center;
  padding-left: 0px;
}

.title-calendar-header-web-left {
  font-family: 'Nunito-Bold';
  font-size: 25px;
  color: #333333;
}

.description-calendar-header-web-right {
  font-family: 'Nunito-Regular';
  font-size: 15px;
  color: #666666;
  margin-right: 10px;
}

.calendar-header-web-right {
  display: flex;
  width: 65%;
  height: 70px;
  align-items: center;
  padding-left: 0px;
  justify-content: flex-end;
}

.calendar {
  display: flex;
  flex-direction: column;
  width: fit-content;
  font-size: 14px;
  border-radius: 8px;
  overflow: hidden;
  font-family: Arial, sans-serif;
  margin-top: 8px;
}

.div-calendar-month-control {
  display: flex;
  width: 100%;
  height: 40px;
  /* background-color: #f1f1f1; */
  justify-content: center;
  align-items: center;
}

.calendar-month-control {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
}

.div-calendar-control {
  display: flex;
  cursor: pointer;
}

.text-month-year {
  font-family: 'Nunito-Bold';
  font-size: 15px;
}

.calendar-week-header {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  /* background-color: #f1f1f1; */
  padding: 4px;
}


.calendar-day-of-week {
  text-align: center;
  font-family: 'Nunito-Regular';
  color: #959595;
}

.calendar-body {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 2px;
  padding: 4px;
}

.calendar-day {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: #fff;
  border-radius: 4px;
  cursor: pointer;
}

.selected-calendar-day {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: #fff;
  border-radius: 4px;
  cursor: pointer;
}

.departure-calendar-day {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: #0fbcf9;
  border-radius: 4px;
  cursor: pointer;
  color: #fff;
  font-weight: bold;
}

.return-calendar-day {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: #036f96;
  border-radius: 4px;
  cursor: pointer;
  color: #fff;
  font-weight: bold;
}

.round-trip-calendar-day {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background: linear-gradient(45deg, #036f96 50%, #0fbcf9 50%);
  border-radius: 4px;
  cursor: pointer;
  color: #fff;
  font-weight: bold;
}

.desable-calendar-day {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  color: #c4c4c4;
  background-color: #fff;
  /* border: 1px solid #dddddd; */
  border-radius: 4px;
  cursor: pointer;
}

/* .calendar-day:hover {
  background-color: #f0f0f0;
} */

.arrow-departure-calendar-day {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
}

.div-dates-button-calendar-footer{
  display: flex;
  flex-direction: column;
  width: 100%;
  /* height: 100%; */
  justify-content: center;
  align-items: center;
  /* background-color: #036f96; */
  /* padding-left: 20px;
  padding-right: 20px; */
}

.arrow-return-calendar-day {
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
}

@media (max-width: 1054px) {

  .div-footer-button-calendar {
    /* background-color: #f4f4f4; */
    display: flex;
    position: absolute;
    height: 70px;
    width: 90%;
    justify-content: center;
    align-items: center;
    padding-top: 2px;
    padding-bottom: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    bottom: 0;
    /* margin-top: 20px; */
  }
  
  .div-container-calendar{
    display: flex;
    width: 100%;
    height: auto;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #c4c4c400;
    box-shadow: 0 0 0px rgba(0, 0, 0, 0.5);
    padding-left: 0px;
    padding-right: 0px;
  }
  

  .calendar {
    display: flex;
    flex-direction: column;
    width: fit-content;
    height: 380px;
    /* border: 1px solid #ccc; */
    border-radius: 8px;
    overflow: hidden;
    font-family: Arial, sans-serif;
  }
  
  .div-dates-button-calendar-footer{
    display: flex;
    flex-direction: column;
    width: 100%;
    /* height: 100%; */
    justify-content: center;
    align-items: center;
    /* background-color: #036f96; */
    /* padding-left: 20px;
    padding-right: 20px; */
  }

  .responsive-calendar {
  display: flex;
  /* background-color: #036f96; */
  box-shadow: none;
  /* border: 2px solid #036f96; */
  /* box-shadow: 0 0 5px rgba(0, 0, 0, 0.5); */
}
  
}




