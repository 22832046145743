.div-content-search {
  /* background-color: #2116f3; */
  /* background-color: #E0E0E0; */

  position: relative;
  display: flex;
  /* background-color: #ffffff; */
  width: 50%;
  justify-content: center;
  /* align-items: center;  */
  height: 97%;
  /* margin-right: 10px; */
  font-size: 20px;
  cursor: pointer;
  padding-bottom: 2px;
  
  /* padding-left: 20px; */
  flex-direction: column;
  /* border-radius: 10px; */
  /* border: solid 3px transparent; */

  &:hover{
    background-color: #E0E0E0;
  }
}

.div-content-search-error {
  /* background-color: #2116f3; */
  position: relative;
  display: flex;
  background-color: #ffffff;
  width: 50%;
  justify-content: center;
  /* align-items: center;  */
  height: 100%;
  border-radius: 5px;
  margin-right: 10px;
  font-size: 20px;
  cursor: pointer;
  
  /* padding-left: 20px; */
  flex-direction: column;
  animation: blink 0.3s infinite;
}

@keyframes blink {
  0%, 100% {
    outline: solid 3px transparent;
  }
  50% {
    outline: solid 3px #ff7e7e;
  }
}

.label-input-search {
  /* background-color: #2116f3; */
  width: 93%;
  font-size: 18px;
   font-family: 'Nunito-Regular';
  padding-left: 3px;
  padding-left: 20px;
  /* height: 30%; */
}

.div-input-search {
  display: flex;
  /* flex-direction: row;
  background-color: #1a16f3; */
  width: 92%;
  height: 40%;
  border: none;
  font-size: 18px;
   font-family: 'Nunito-Regular';
  color: rgb(53, 53, 53);
  padding-left: 8px;
}

.input-search {
  /* background-color: #16c7f3; */
  width: 85%;
  height: 100%;
  border: none;
  font-size: 18px;
   font-family: 'Nunito-Regular';
  color: rgb(53, 53, 53);

  &:focus{
    outline: none; 
  }
}

.div-icon-clean {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  color: #474747;
  /* background-color: rgb(2, 163, 13); */
  width: 15%;
  height: 100%;
  
}


.div-icon-search {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  color: #474747;
  /* background-color: rgb(2, 163, 13); */
  width: auto;
  height: 100%;
  margin-right: 10px;
  
}

.div-inputs-search-results {
  background-color: #ffffff;
  /* background-color: #3408e6e3; */
  /* filter: blur(5px); */
  border: 1px solid #d4d4d4; 
  display: flex;
  /* width: 600px; */
  padding-bottom: 10px;
  height: auto;
  max-height: 340px;
  /* justify-content: center;
  align-items: center; */
  position: absolute;
  border-radius: 5px;
  top: 110%; 
  overflow: hidden;
  z-index: 10;
  flex-direction: column;
  cursor: default;
  /* padding-top: 20px; */
}

/* .suggestions-list {
  display: flex;
  justify-content: flex-start;
  justify-content: flex-start;
}*/

.div-place-item {
  display: flex;
  /* background-color: #161af3; */
  justify-content: flex-start;
  justify-content: flex-start;
  margin-top: 20px;
  padding-left: 10px;
  padding-right: 20px;
  align-items: center;

  &:hover{
    background-color: #eaeaea;
  }
}
.div-airport-item {
  display: flex;
  /* background-color: #f316b4; */
  justify-content: flex-start;
  justify-content: flex-start;
  margin-top: 20px;
  font-size: 17px;
  padding-left: 35px;
  padding-right: 20px;
  align-items: center;

  &:hover{
    background-color: #f1f1f1;
  }
} 

.icon-place {
  /* font-size: 35px; */
  width: 40px;
  font-size: 100%;
  color: #00688D;
  fill: #00688D
}

.icon-airport {
  font-size: 20px;
  color: #00688D;
  fill: #00688D;
  margin-right: 10px;
  /* margin-left: 20px; */
}

.text-place-airport{
  font-size: 15px;
  font-family: 'Nunito-Regular';
  cursor: pointer;
  white-space: nowrap;
  color: #666;
}

.div-input-search-mobile {
  display: flex;
  flex-direction: row;
  background-color: #F9F9F9;
  width: 100%;
  height:50px;
  /* border: none;  */
  padding-left: 10px;
  padding-right: 20px;
  border-radius: 10px;
  /* border: #666666; */
  border: 1px solid #DDDDDD; 
  margin-top: 20px;
}

.input-search-mobile {
  background-color: transparent;
  width: 100%;
  height: 95%;
  border: none;
  font-size: 18px;
   font-family: 'Nunito-Regular';
  color: rgb(53, 53, 53);

  &:focus{
    outline: none; 
    scroll-margin-top: 100px;
  }
}

.div-inputs-search-results-mobile {
  background-color: #F9F9F9;
  /* background-color: #3408e6e3; */
  /* filter: blur(5px); */
  border: 1px  solid #DDDDDD; 
  border-top-width: 0;
  display: flex;
  width: 99.4%;
  padding-bottom: 10px;
  height: auto;
  max-height: 200px;
  /* justify-content: center;
  align-items: center; */
  position: absolute;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  top: 150%; 
  overflow: hidden;
  z-index: 10;
  flex-direction: column;
  overflow-y: scroll;
  /* margin-top: 10px; */
  /* padding-top: 20px; */
}

@media (max-width: 1054px) {
  .div-content-search {
    /* background-color: #e30a0a; */
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    height: 45%;
    border-radius: 0px;
    padding-left: 0px;
    /* padding-top: 5px; */
    flex-direction: row;
  }

  .div-content-search-error{
    display: flex;
    /* background-color: #ffffff; */
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    height: 18%;
    border-radius: 10px;
    padding-left: 0px;
    flex-direction: row;
    animation: blink 0.3s infinite;
  }
  
  .label-input-search {
    display: none;
    background-color: #f316b4;
    width: 10%;
    height: 80%;
    font-size: 18px;
     font-family: 'Nunito-Regular';
    /* margin-left: 20px; */
    /* padding-left: 20px; */
    /* justify-content: center;  */
    align-items: center;
    /* max-width: 60px;  */
    /* min-width: 58px; */
    /* height: 30%; */
  }

  .div-input-search {
    display: flex;
    flex-direction: row;
    /* background-color: #1a16f3; */
    width: 100%;
    height: 80%;
    border: none; 
    padding-left: 20px;
    padding-right: 20px;
  }
  
  .input-search {
    /* background-color: #f32c16; */
    display: flex;
    width: 100%;
    height: 100%;
    border: none;
    font-size: 18px;
     font-family: 'Nunito-Regular';
    color: rgb(53, 53, 53);
  
    &:focus{
      outline: none; 
    }
  }

  .div-icon-clean {
    /* background-color: rgb(2, 163, 13); */
    display: flex;
    justify-content: center;
    align-items: center;
    width: 8%;
    height: 100%;
    margin-top: 4px;
  }

  .div-inputs-search-results {
    background-color: #ffffff;
    /* background-color: #3408e6e3; */
    /* filter: blur(5px); */
    border: 1px solid #d4d4d4; 
    display: flex;
    width: 100%;
    padding-bottom: 10px;
    height: auto;
    max-height: 340px;
    /* justify-content: center;
    align-items: center; */
    position: absolute;
    border-radius: 5px;
    top: 110%; 
    overflow: hidden;
    z-index: 10;
    flex-direction: column;
    /* padding-top: 20px; */
  }

}


@media (max-width: 440px) {
  /* .div-content-search {
    display: flex;
    background-color: #ffffff;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    height: 45%;
    border-radius: 10px;
    padding-left: 0px;
    flex-direction: row;
  } */

  .div-content-search-error{
    display: flex;
    background-color: #ffffff;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    height: 10%;
    border-radius: 10px;
    padding-left: 0px;
    flex-direction: row;
    animation: blink 0.3s infinite;
  }


  .label-input-search {
    display: none;
    background-color: #f316b4;
    width: 10%;
    height: 80%;
    font-size: 18px;
     font-family: 'Nunito-Regular';
    /* margin-left: 20px; */
    /* padding-left: 20px; */
    /* justify-content: center;  */
    align-items: center;
    /* max-width: 60px;  */
    /* min-width: 58px; */
    /* height: 30%; */
  }

  .div-input-search {
    display: flex;
    flex-direction: row;
    /* background-color: #1a16f3; */
    width: 100%;
    height: 70%;
    border: none; 
    padding-left: 20px;
    padding-right: 20px;
  }


  
  .input-search {
    /* background-color: #f32c16; */
    display: flex;
    width: 100%;
    height: 100%;
    border: none;
    font-size: 18px;
     font-family: 'Nunito-Regular';
    color: rgb(53, 53, 53);
  
    &:focus{
      outline: none; 
    }
  }

  .div-icon-clean {
    /* background-color: rgb(2, 163, 13); */
    display: flex;
    justify-content: center;
    align-items: center;
    width: 8%;
    height: 100%;
  }

  .div-inputs-search-results {
    background-color: #ffffff;
    /* background-color: #3408e6e3; */
    /* filter: blur(5px); */
    border: 1px solid #d4d4d4; 
    display: flex;
    width: 100%;
    padding-bottom: 10px;
    height: auto;
    max-height: 340px;
    /* justify-content: center;
    align-items: center; */
    position: absolute;
    border-radius: 5px;
    top: 110%; 
    overflow: hidden;
    z-index: 10;
    flex-direction: column;
    /* padding-top: 20px; */
  }

}
