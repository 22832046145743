.div-toast {
  position: fixed;
  top: 20px;
  right: 20px;
  width: 350px;
  height: 120px;
  border-radius: 10px;
  z-index: 10000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  animation: slide-in 0.5s forwards;
}

.div-toast.hide {
  animation: slide-out 0.5s forwards;
}

.div-toast.none {
  display: none;
}

.toast-content {
  display: flex;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  color: aliceblue;
  font-family: 'Nunito-Regular';
  font-size: 18px;
  justify-self: center;
  
  /* background-color: #4caf50; */
}

.toast-progress-bar {
  display: flex;
  position: fixed;
  height: 5px;
  background-color: #00000056; /* Cor verde para a barra de progresso */
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  width: 100%; /* Começar com largura total */
  transition: width 0.1s linear;
  bottom: 0px;
}

.div-toast-close-icon {
  display: flex;
  position: absolute;
  top: 10px;
  right: 10px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

@keyframes slide-in {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slide-out {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(100%);
    opacity: 0;
  }
}

@media (max-width: 1054px) {
  .div-toast {
    position: fixed;
    top: 20px;
    transform: translateX(-50%);
    width: 90%;
    height: 90px;
    border-radius: 10px;
    z-index: 10000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    animation: slide-in 0.5s forwards;
  }
  
}
