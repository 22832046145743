.div-button{
  display: flex;
  /* background-color: #ffcc00; */
  background-color: #ffcc00;
  width: 180px;
  justify-content: center;
  align-items: center;
  height: 100%;
  border-radius: 5px;
  cursor: pointer;
  border: none;
  flex-direction: row;
}
.icon-button{
  font-size: 30px;
  color: rgb(55, 55, 55);
  margin-bottom: 5px;
  margin-right: 10px;
}

.text-title-button {  
  font-size: 15px;
  font-family: 'Nunito-Regular';
  color: rgb(55, 55, 55);
}

.text-title-button-bold {  
  font-size: 17px;
  font-family: 'Nunito-Bold';
  color: #9A9A98;
}

.text-title-button-bold-confirm {  
  font-size: 17px;
  font-family: 'Nunito-Bold';
  color: #F9F9F9;
}


.text-title-button-seller {
  font-size: 13px;
  font-family: 'Nunito-Regular';
  color: rgb(255, 255, 255);
}

.button-footer-calendar {
  display: flex;
  background-color: #00A9D6;
  width: 50%;
  height: 55px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border: none;
  cursor: pointer;
  

  &:hover{
    background-color: #04afe3;
}

}

.cancel-button-footer-calendar {
  display: flex;
  background-color: #ffffff;
  width: 50%;
  height: 55px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border: 2px solid #00A9D6;
  cursor: pointer;

  &:hover{
      /* background-color: #eaeaea; */
  }
}

.div-button-web {
  display: flex;
  position: relative;
  background-color: #019FCF;
  width: 85%;
  justify-content: center;
  align-items: center;
  height: 60px;
  border-radius: 5px;
  cursor: pointer;
  border: none;
  flex-direction: row;

  cursor: pointer;
  margin-top: 10px;
}

.div-button-mobile{
  display: flex;
  position: fixed;
  background-color: #019FCF;
  width: 85%;
  justify-content: center;
  align-items: center;
  height: 60px;
  border-radius: 5px;
  cursor: pointer;
  border: none;
  flex-direction: row;
  bottom: 15px;
}

.text-title-button-footer-modal{
  display: flex;
  color: #ffffff;
  font-family: 'Nunito-Bold';
  font-size: 22px;
}

.text-flights-serach-button {
  display: flex;
  color: #333333;
  font-family: 'Nunito-Bold';
  font-size: 17px;
  text-align: left;
  margin-left: 10px;
}

@media (max-width: 1054px) {
  .div-button{
    display: flex;
    background-color: #ffcc00;
    width: 99%;
    justify-content: center;
    align-items: center;
    height:  100%;
    border-radius: 10px;
    cursor: pointer;
    border: none;
    margin-top: 10px;
    flex-direction: row;
  }

  .icon-button{
    font-size: 25px;
    color: rgb(55, 55, 55);
    margin-right: 10px;
    margin-top: 7px;
    margin-right: 10px;
  }
  
  .text-title-button {
    font-size: 15px;
    font-family: 'Nunito-Regular';
    color: rgb(55, 55, 55);
  }

  .text-flights-serach-button {
    display: flex;
    color: #333333;
    font-family: 'Nunito-Bold';
    font-size: 17px;
    text-align: left;
    margin-left: 0px;
  }
}

@media (max-width: 440px) {
  .text-title-button-seller {
    font-size: 11px;
    font-family: 'Nunito-Regular';
    color: rgb(255, 255, 255);
  }
}


