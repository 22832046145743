
.div-checkbox {
  display: flex;
  aspect-ratio: 1/1;
  border-radius: 8px;
  cursor: pointer;
  border: 2px solid #00688D;
  justify-content: center;
  align-items: center;
  padding-left: 1px;
  padding-right: 1px;
  padding-top: 3px;
}


.label-check-box {
  color: #2f2f2f;
  font-weight: normal;
  font-size: 15px; 
  margin-top: 2px;
  font-family: 'Nunito-Regular';
  cursor: pointer;

  &:hover{
    background-color: #f5f5f5;
  }
}
