.nav-list {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex: 1
}

@media (max-width: 1054px) {
  .nav-list {
    flex-direction: column;
    align-items: baseline;
  }
}