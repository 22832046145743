
.div-container-float-container{

  display: flex;
  width: fit-content;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 10px;
  position: absolute;
  top: 110%;
  z-index: 1000;
}

@media (max-width: 1054px) {

  .div-container-float-container{
    display: flex;
    width: 100%;
    height: auto;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #c4c4c400;
    box-shadow: 0 0 0px rgba(0, 0, 0, 0.5);
    padding-left: 0px;
    padding-right: 0px;
  }
  

}




