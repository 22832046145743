/* Estilo para a faixa (track) */
.slider-filter .range-slider__track {
    background-color: #ddd !important; /* Cor de fundo da faixa */
  }
  
  /* Estilo para o preenchimento da faixa (track-fill) */
  .slider-filter .range-slider__track-fill {
    background-color: #4caf50 !important; /* Cor de preenchimento da faixa */
  }
  
  /* Estilo para o polegar (thumb) */
  .slider-filter .range-slider__thumb {
    background-color: #00688D !important; /* Cor do polegar */
    border: 2px solid #00688D !important; /* Borda do polegar */
  }

  .slider-filter .range-slider__range {
    background-color: #00688D !important; /* Cor do polegar */
  }

  /* Estilo para o polegar ativo (thumb) quando está sendo arrastado */
  .slider-filter .range-slider__thumb:active {
    background-color: #00688D !important; /* Cor do polegar ativo */
    border: 2px solid #00688D !important; /* Borda do polegar ativo */
  }
  