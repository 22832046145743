.progress-bar {
  width: 100%;
  background-color: #4F4F4F;
  height: 80px;
  border-radius: 15px;
  position: relative;
  margin-top: 0px;
}

.progress {
  display: flex;
  width: 0%; 
  background-color: #002087;
  height: 100%;
  border-radius: 15px;
  transition: width 0.5s ease-in-out;
  justify-content: flex-end;
}

.div-percent-status {
  display: flex;
  height: 80px;
  aspect-ratio: 1/1;
  position: absolute;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  right: 0;
  z-index: 10;
}

.text-percent {
  color: #FFFFFF;
  font-weight: normal;
  font-size: 25px; 
  font-family: 'Nunito-Bold';
}

.div-search-status {
  /* background-color: #0e30a1; */
  display: flex;
  height: 80px;
  width: fit-content;
  position: absolute;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  left: 0;
  z-index: 10;
}

.text-search-status {
  color: #FFFFFF;
  font-weight: normal;
  font-size: 25px; 
  font-family: 'Nunito-Regular';
}

.progress {
  display: flex;
  width: 75%;
  height: 100%; 
  position: relative;
  border-radius: 15px; 
  overflow: hidden;
}

.striped {
  background-image: linear-gradient(
    45deg,
    #6E9BCE 25%,
    #5589C5 25%,
    #5589C5 50%,
    #6E9BCE 50%,
    #6E9BCE 75%,
    #5589C5 75%,
    #5589C5
  );
  background-size: 40px 40px;
  animation: moveStripes 1s linear infinite;
}

@keyframes moveStripes {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 40px 0;
  }
}


@media (max-width: 1054px) {
  .progress-bar {
    width: 90%;
    background-color: #4F4F4F;
    height: 70px;
    border-radius: 15px;
    position: relative;
    margin-top: 20px;
  }

  .progress {
    display: flex;
    width: 0%; 
    background-color: #002087;
    height: 100%;
    border-radius: 15px;
    transition: width 0.5s ease-in-out;
    justify-content: flex-end;
  }
  
  .div-percent-status {
    display: flex;
    height: 70px;
    aspect-ratio: 1/1;
    position: absolute;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    right: 0;
    z-index: 10;
  }

  .text-percent {
    color: #FFFFFF;
    font-weight: normal;
    font-size: 20px; 
    font-family: 'Nunito-Bold';
  }

  .div-search-status {
    /* background-color: #0e30a1; */
    display: flex;
    height: 70px;
    width: fit-content;
    position: absolute;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
    left: 0;
    z-index: 10;
  }

  .text-search-status {
    color: #FFFFFF;
    font-weight: normal;
    font-size: 18px; 
    font-family: 'Nunito-Regular';
  }
}
