.div-mobile-content-menu {
  display: flex;
  flex-direction: column;
  width: 90%;
  padding: 24px 24px 0 24px;
}

.div-mobile-menu {
  display: none;
  width: 50px; 
  width: 50px; 
  background-color: #00aad6;
  position: absolute;
  left: 0;
  top: 0;
}

@media (max-width: 1054px) {
  .div-mobile-menu {
    display: flex;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: -100%; 
    border-color: #00aad6;
    z-index: 2;
    transition: left 0.3s ease;
  }
  
  .div-mobile-menu.show {
    left: 0; 
    transition: left 0.3s ease;
  }

  .icon-mobile-close-menu{
    display: flex;
    font-size: 35px;   
    color: #fff;
    margin-right: 1px;
    margin-bottom: 2px;
    position: absolute;
    right: 0;
    top: 0;
    margin-right: 10px;
    margin-top: 10px;
  } 
}
