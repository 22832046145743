.div-passengers-selector {
  /* background-color: #ffcc00; */
  flex-direction: column;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-self: center;
  margin-bottom: 0px;
}

.div-passengers-selector-row {
 /* background-color: #ffcc00; */
  flex-direction: row;
  display: flex;
  width: 150px;
  height: 100%;
  justify-content: flex-start;
  align-self: flex-end;
  margin-bottom: 8px;
}

.div-left-passengers-selector-icon {
  /* background-color: #0d00ff; */
  flex-direction: row;
  display: flex;
  height: fit-content;
  width: 100%;
  margin-left: 0px;
  justify-content: center;
  align-items: flex-end;
}


.div-left-passengers-selector {
  /* background-color: #0d00ff; */
  flex-direction: column;
  display: flex;
  width: 100%;
  height: fit-content;
  justify-content: center;
  align-items: center;
  padding-right: 0px;
}

.div-left-passengers-selector-2 {
  /* background-color: #0d00ff; */
  flex-direction: row;
  display: flex;
  width: 80%;
  height: 100%;
  justify-content: center;
  align-items: center;
  padding-right: 0px;
  gap: 10px;
}

.div-content-passengers-selector {
  /* background-color: #2fff00; */
  flex-direction: row;
  display: flex;
  height: 100%;
  justify-content: space-between;
  align-items: center;
}

.div-content-passengers-selector-2 {
  /* background-color: #2fff00; */
  flex-direction: row;
  display: flex;
  height: 100%;
  justify-content: space-between;
  align-items: center;
}

.text-content-passengers-selector {
  display: flex;
  font-size: 13px;
  font-family: 'Nunito-Bold';
  color: #666666;
  margin-left: 2px;
  margin-right: 3px;
}




@media (max-width: 1055px) {
  .div-left-passengers-selector {
    /* background-color: #0d00ff; */
    flex-direction: row;
    display: flex;
    width: 80%;
    height: 100%;
    justify-content: center;
    align-items: center;
    padding-right: 20px;
  }

  .text-content-passengers-selector {
    display: flex;
    font-size: 15px;
    font-family: 'Nunito-Bold';
    color: #666666;
    margin-left: 2px;
    margin-right: 0px;
  }

  .div-passengers-selector {
    /* background-color: #ffcc00; */
    flex-direction: row;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: flex-start;
    align-self: center;
    margin-bottom: 0px;
  }

  .div-left-passengers-selector-icon {
    /* background-color: #0d00ff; */
    flex-direction: row;
    display: flex;
    height: 100%;
    width: 20%;
    /* padding-left: 20px; */
    margin-left: 5px;
    justify-content: center;
    align-items: center;
  }
  
}



@media (max-width: 440px) {
  .text-title-button-seller {
    font-size: 11px;
    font-family: 'Nunito-Regular';
    color: rgb(255, 255, 255);
  }
}


