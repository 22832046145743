.div-offer-button {
  display: flex;
  flex-direction: row;
  background-color: #219653;
  width: 100%;
  height: 50px;
  justify-content: space-between;
  align-items: center;
  border-radius: 12px;
  padding-left: 15px;
  padding-right: 10px;
  cursor: pointer;

  &:hover {
   background-color: #26b664;
  }
}

.text-offer-button {
  font-family: 'Nunito-Bold';
  font-size: 13px;
  color: #FFFFFF;
}



