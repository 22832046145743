.div-mobile-flights-filter {
  display: flex;
  width: 100%;
  height: auto;
  position: fixed;
  top: 95px;
  left: -100%; 
  z-index: 2;
  transition: left 0.3s ease;
  /* margin-top: 95px; */
  background-color: transparent;
  overflow-y: auto;
    /* background-color: rgb(255, 255, 255); */
}

.div-mobile-flights-filter.show {
  left: 0; 
  transition: left 0.3s ease; 
   /* overflow-y: scroll; */
     /* background-color: rgb(255, 255, 255); */
}
.div-mobile-flights-filter-content {
  display: flex;
  width: 100vh;
  height: 100vh;
  z-index: 3;
  /* pointer-events: none; */

}

.icon-mobile-close-flights-filter{
  display: flex;
  font-size: 35px;   
  color: #f20000;
  margin-right: 1px;
  margin-bottom: 2px;
  position: absolute;
  right: 0;
  top: 200px;
  margin-right: 10px;
  margin-top: 10px;
} 

.div-body-content {
  display: flex;
  /* background-color: rgb(236, 0, 0); */
  width: 100%;
  justify-content: center;
  /* align-items: center; */
  /* height: 100; */
  /* margin-bottom: 50px; */
}

.div-left-body-content {
  background-color: rgb(255, 255, 255); 
  display: flex;
  flex-direction: column;
  min-width: 300px;
  width: 300px;
  padding-right: 0px;
  justify-content: flex-end;
  padding-top: 0px;
  height: fit-content;
  align-items: center;
  margin-right: 20px;
  margin-top: 20px;
  border-radius: 10px;
  /* max-width: 300px; */

}


/* REMOVE */
.div-mid-body-content {
  /* background-color: #002087; */
  display: flex;
  /* flex: 0.40;  */
  width: fit-content;
  /* max-width: 850px;   */
  justify-content: flex-start;
  align-items: center;
  height: auto;
  flex-direction: column;
}

.div-mid-body-inline {
  display: flex;
  /* flex: 1; */
  /* background-color: rgb(0, 119, 174); */
  width: 700px;
  justify-content: center;
  align-items: center;
  height: 120px;
  min-height: fit-content;
  margin-top: 20px;
}

.div-button-try-again-search {
  display: flex;
  width: 220px;
  height: 70px;
  margin-top: 20px;
}

.div-right-content {
  /* background-color: rgb(174, 104, 0); */
  display: flex;
  flex: 0.25;
  /* width: 100%; */
  min-width: 300px;
  justify-content: flex-start;
  align-items:flex-start;
  height: fit-content;
  margin-left: 20px;
  margin-top: 20px;
  flex-direction: column;
  gap: 10px;
  
  /* flex-direction: column; */
}

.div-loading-flights-search {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.text-loading-flights-search {
  color: #101010;
  font-weight: normal;
  font-size: 18px; 
  font-family: 'Nunito-Regular';
}

  /* Pagination Styles */
.div-pagination-container {
  /* background-color: #af0505; */
  display: flex;
  flex-direction: column;
}

.div-pagination-container-cards {
  /* background-color: #333; */
  display: 'flex';
  flex-direction: 'column';
  height: 1000;
  width: 100%;
  overflow-y: 'auto';
  padding-left: 10;
  padding-right: 10;
}

.paginationContainer {
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  list-style-type: none;
  padding: 0;  
}
.page-link {
  display: inline-block;
  padding: 5px 10px;
  margin: 5px;
  /* width: 20px; */
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  color: #333;
  text-decoration: none;
}

.prev-link {
  /* Estilos para os botões "Próximo" e "Anterior" */
}

.break-link {
  /* Estilos para o marcador "..." */
}


.active {
  /* display: flex; */
  /* color: rgb(255, 255, 255); */
  background-color: rgb(0, 32, 135);
  border-radius: 5px;
  /* width: 22px;
  height: 22px;
  /* border-radius: 50%; */
  justify-content: center; 
}

.div-no-filtered-flights {
  /* color: rgb(255, 0, 0); */
  display: flex;
  width: 800px;
  height: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 10;
  padding-right: 10;
}

.text-no-filtered-flights{
  color: #6a6a6a;
  font-weight: normal;
  font-size: 22px; 
   font-family: 'Nunito-Regular';
}

.title-text-offers-tips{

  color: #00688d;
  font-weight: normal;
  font-size: 35px; 
   font-family: 'Nunito-Regular';
}

.div-blog-posts-content {
    /* background-color: #002087; */
  display: flex;  
  width: 100%;
  height: auto;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 0px;
}

.card-grid {
  /* background-color: #650087; */
  display: grid;
  margin-top: 40px;
  margin-bottom: 40px;
  width: 60%;
  max-width: 1200px;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 15px;
}


.div-voopter-grid {
  background-color: #edf0f2;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
  padding-bottom: 50px;
  
}

.voopter-grid {
  display: flex;
  /* min-width: 1100px;  */
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  /* background-color: #650087; */
}

.div-phones-voopter-grid {
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 350px; */
}

.img-phones-voopter-grid {
  display: flex;
  width: 250px;
}

.div-apps-voopter-grid {
  /* background-color: #650087;*/
  display: flex;
  flex-direction: column;
  width: 300px;
  justify-items: center;
  height: 300px;
}

.div-store-apps {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* width: 100%;  */
}

.div-store-apps-ios {
  display: flex;
  background-color: #FFCC00;
  flex-direction: row;
  height: 60px;
  width: 60px;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover{
      background-color: rgb(255, 218, 72);
  }
}

.div-store-apps-android {
  display: flex;
  background-color: #FFCC00;
  flex-direction: row;
  height: 60px;
  width: 60px;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  cursor: pointer;

  &:hover{
    background-color: rgb(255, 218, 72);
  }
}

.div-icon-ios {
  display: flex;
  font-size: 30px;
  margin-bottom: 5px;
}

.text-phone-platform {
  display: flex;
  font-family: 'Nunito-Regular';
  color: #878787;
  margin-left: 10px;
}

.text-apps-voopter-grid {
  font-size: 25px;
  color: #878787;
  font-family: 'Nunito-Regular';
  margin-bottom: 20px;
}

.div-video-voopter-grid {
  /* background-color: #650087; */
  display: flex;
  flex-direction: column;
  width: 90%;
  /* width: 450px; */
  justify-items: center;
  justify-content: center;
  align-items: center;
}


.text-item-voopter-grid {
  font-size: 20px;
  color: #00678b;
  font-family: 'Nunito-Regular';
  margin-bottom: 20px;
}

.img-video-voopter-grid {
  display: flex;
  cursor: pointer;
  width: 450px;
}

.div-filter-button-mobile {
  /* background-color: #650087; */
  width: 100%;
  display: none;
  flex-direction: row;  
  margin-top: 20px;
  justify-content: flex-end;
  
}

.text-filter-button-mobile {
  color: #00688d;
  font-weight: normal;
  font-size: 20px; 
   font-family: 'Nunito-Regular';
  cursor: pointer;
  margin-right: 20px;
 
}

.div-ad-banner-search{
  display: flex;
  margin-bottom: 20px;
}

.div-right-content-mobile {
   
  /* background-color: rgb(174, 104, 0); */ 
 display: none; 
 flex: 0.25;
 width: 30%;
 justify-content: flex-start;
 align-items: center;
 height: 100%;
 margin-left: 20px;
 margin-top: 20px;
 flex-direction: column;
}

.div-result-message {
  background-color: #6FCF97;
  display: flex; 
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  border: 1px solid #ffffff00;
  border-radius: 10px;
  margin-top: 0px;
  justify-content: center;
}

.text-result-message-top {
  display: flex;
  opacity: 0; 
  flex-direction: row;
  width: 90%;
  color: #ffffff;
  font-weight: normal;
  font-size: 18px;
  font-family: 'Nunito-Bold';
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  animation: fadeIn 1s 1s;
  animation-fill-mode: forwards;
}

.text-result-message-bottom {
  display: flex;
  opacity: 0; 
  flex-direction: row;
  width: 90%;
  color: #ffffff;
  font-weight: normal;
  font-size: 18px;
  font-family: 'Nunito-Regular';
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  animation: fadeIn 1s 1s;
  animation-fill-mode: forwards;
}


.animation-result-message {
  position: absolute;
  top: 10px;
  left: calc(50% - 35px);
  animation-name: slideRight;
  animation-duration: 0.6s; 
  animation-delay: 1s; 
  animation-fill-mode: forwards;
}

@keyframes slideRight {
  from {
    left: calc(50% - 35px);
  }
  to {
    left: calc(100% - 70px); 
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.div-content-footer-flight-card-modal{
  background-color: #ffffff;
  display: flex;
  width: 100%;
  max-height:  calc(100dvh - 100px);
  /* height: 85%; */
  align-items: center;
  flex-direction: column;
  overflow-y: auto;
  position: relative;
  flex-grow: 1;
}

.div-content-footer-filters-modal-father {
  /* background-color: #e70101; */
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  flex-direction: column;
}

.div-content-footer-filters-modal{
  background-color: #ffffff;
  display: flex;
  width: 100%;
  height: calc(90dvh - 100px);
  align-items: center;
  flex-direction: column;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  position: relative;
  /* flex-grow: 1; */
}

.div-content-footer-filters-modal-bottom{
  /* background-color: #220bef; */
  display: flex;
  width: 100%;
  height:  calc(13vh);
  /* min-height:  calc(100vh - 100px); */
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  flex-grow: 1;
}

.div-content-footer-flight-card-modal-content{
  background-color: #ffffff;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  flex-direction: column;
  overflow-y: scroll;
  position: relative;
}

.div-fligts-filters-anchor {
  background-color: #FFFFFF;
  display: flex;
  width: 100%;
  height: 0px;
  margin-top: 20px;
}

.div-fligts-filters {
  background-color: #FFFFFF;
  flex-direction: column;
  display: flex;
  width: 100%;
  height: fit-content;
  margin-top: 0px;
  position: -webkit-sticky; 
  position: sticky;
  top: 0;
  z-index: 10; 
  transition: all 0.3s ease; 
  padding-top: 10px;
  padding-bottom: 10px;
  box-shadow: 0 10px 10px -5px rgba(0, 0, 0, 0.2); /* sombra da borda */
}

.div-fligts-filters-up {
  /* background-color: #890000; */
  display: flex;
  flex-direction: row;
  width: fit-content;
  height: fit-content;
  justify-content: center;
  align-items: center;
}

.div-fligts-filters-mid {
  /* background-color: #890000; */
  display: flex;
  flex-direction: row;
  width: 100%;
  height: fit-content;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.text-iata-fligts-filters-up {
  font-size: 30px;
  font-family: 'Nunito-Bold';
  color: #333333;
}

.icon-departure-fligts-filters-up {
  display: flex;
  font-size: 40px;
  color: #2D9CDB;
}

.icon-return-fligts-filters-up {
  display: flex;
  font-size: 40px;
  color: #2F80ED;
}

.div-profile-icon-fligts-filters-up {
  display: flex;
  margin-left: 10px;
  margin-top: 1px;
}

.text-passengers-fligts-filters-up{
  font-size: 13px;
  font-family: 'Nunito-Regular';
  color: #4F4F4F;
}

.div-edit-icon-fligts-filters-up {
  display: flex;
  margin-left: 10px;
}

.div-dates-fligts-filters-mid{
  /* background-color: #890000; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.text-dates-fligts-filters-web{
  font-size: 13px;
  font-family: 'Nunito-Bold';
  color: #4F4F4F;
  /* margin-left: 2px; */
}

.text-dates-fligts-filters-mid{
  font-size: 13px;
  font-family: 'Nunito-Regular';
  color: #4F4F4F;
  /* margin-left: 2px; */
}


.div-fligts-filters-bottom {
  /* background-color: #890000; */
  display: flex;
  flex-direction: row;
  width: 100%;
  height: fit-content;
  margin-top: 10px;
  overflow-x: auto;
  gap: 20px;
  padding-left: 20px;
  padding-right: 20px; 
  box-sizing: border-box;
}

.div-fligts-filters-bottom::-webkit-scrollbar {
  display: none;
}

.div-fiters-content {
  /* background-color: #002087; */
  display: flex;
  width: 90%;
  height: 100%;
  margin-top: 30px;
  flex-direction: column;
}

.div-fiters-element {
  display: flex;
  width: 100%;
  height: fit-content;
  flex-direction: column;

  /* flex-direction: row; */
}

.div-title-fiters-element {
  /* background-color: #002087; */
  display: flex;
  width: 100%;
  height: 50px;
  flex-direction: row;
  align-items: center;
}

.text-title-fiters-element {
  display: flex;
  font-size: 18px;
  font-family: 'Nunito-Bold';
  margin-left: 20px;
  color: #333333;
}

.div-body-fiters-element-column {
  display: flex;
  width: 100%;
  height: fit-content;
  flex-direction: column;
  padding-left: 8px;
}

.div-body-fiters-element {
  display: flex;
  width: 100%;
  height: fit-content;
  flex-direction: column;
  padding-left: 8px;
}


.div-body-fiters-element-left {
  /* background-color: #002087; */
  display: flex;
  width: 50%;
  height: fit-content;
  flex-direction: column;
}

.div-body-fiters-element-right {
  display: flex;
  width: 50%;
  height: fit-content;
  flex-direction: column;
}

.div-body-fiters-element-content {
  display: flex;
  width: 100%;
  height: 50%;
  flex-direction: column;
}

.div-body-fiters-element-item {
  /* background-color: #002087; */
  display: flex;
  width: 100%;
  height: 50px;
  flex-direction: row;
  align-items: center;
}

.text-body-fiters-element-item {
  display: flex;
  font-size: 18px;
  font-family: 'Nunito-Regular';
  margin-left: 15px;
  color: #333333;
}

.div-dashed-line-fiters {
  border-top: 1px dashed #AAAAAA;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
  /* margin: 10px 0; */
}

.div-body-fiters-element-top {
  /* background-color: #002087; */
  display: flex;
  width: 100%;
  height: fit-content;
  flex-direction: row;
}


.text-body-fiters-element-top-item {
  display: flex;
  font-size: 18px;
  font-family: 'Nunito-Regular';
  color: #333333;
}

.text-body-fiters-element-top-item-bold {
  display: flex;
  font-size: 18px;
  font-family: 'Nunito-Bold';
  color: #333333;
}


.div-body-fiters-element-airports {
  display: flex;
  width: 100%;
  height: fit-content;
  flex-direction: column;
  padding-left: 8px;
  /* padding-bottom: 20px; */
}

.div-slider-filter{
  display: flex;
  width: 90%;
  height: 40px;
  margin-top: 20px;
}

.div-airports-list-fiters-element {
  /* background-color: #002087; */
  display: flex;
  width: 100%;
  height: fit-content;
  flex-direction: column;
}

.text-iata-fiters-element {
  display: flex;
  font-size: 18px;
  font-family: 'Nunito-Bold';
  margin-left: 15px;
  color: #333333;
}

.text-airports-fiters-element {
  max-width: 150px;
  display: flex;
  font-size: 18px;
  font-family: 'Nunito-Regular';
  color: #333333;
}

.div-content-footer-filters-modal-button-group{
  display: flex;
  justify-content: center;
  align-items: center;
  height: fit-content;
  width: 90%;
}

.image-flight-filter {
  display: flex;
  width: 45px;
  margin-left: 15px;
  margin-right: 15px;
}

/* .div-fligts-filters-absolute {
  background-color: #FFFFFF;
  display: flex;
  position: absolute;
  top: 357px
  left: 0;;
  width: 100%;
  height: 100px;
} */


.div-search-result-header {
  /* background-color: #FFFFFF; */
  display: flex;
  width: 100%;
  height: 110px;
  justify-content: center;
  align-items: center;
 }


.div-search-result-header-content {
  /* background-color: #FFFFFF; */
  display: flex;
  flex-direction: row;
  width: 1330px;
  height: 100%;
}

.div-search-result-header-content-left {
  /* background-color: #FFFFFF; */
  display: flex;
  flex-direction: column;
  width: 45%;
  height: 100%;
  /* margin-right: 10px; */
  border-radius: 10px;
  /* justify-content: center; */
}

.div-search-result-header-content-left-up-content {
  /* background-color: #2D9CDB; */
  display: flex;
  width: 100%;
  height: 65%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.div-search-result-header-content-left-bottom-content {
  /* background-color: #2ddb9b; */
  display: flex;
  width: 100%;
  height: 35%;
}

.div-search-result-header-content-right {
  display: flex;
  width: 55%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.div-skeleton-filter {
  /* background-color: #bd0404; */
  display: flex;
  width: 100%;
  height: 800px;
  flex-direction: column;
  align-items: center;
}

.div-skeleton-filter-item{
  /* background-color: #bd0404; */
  display: flex;
  width: 90%;
  height: fit-content;
  flex-direction: row;
  align-items: center;
  /* margin-top: 50px; */
  
}

.div-flight-card-skeleton {
  background-color: #FFFFFF;
  display: flex;
  width: 700px;
  height: 160px;
  flex-direction: row;
  margin-top: 20px;
  border-radius: 12px;
}

.div-flight-card-skeleton-left {
  /* background-color: #ad0b0b; */
  display: flex;
  width: 70%;
  height: 100%;
  flex-direction: column;
}

.div-flight-card-skeleton-right {
  background-color: #d6d6d64e;
  display: flex;
  width: 30%;
  height: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  }

.div-flight-card-skeleton-left-item {
  display: flex;
  width: 100%;
  height: 50%;
  align-items: center;
}

.div-banner-home-desktop-interface {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.div-banner-home-desktop-footer{
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: #edf0f2;
  padding-bottom: 50px;
}

.div-banner-home-desktop-contents {
  display: flex;
  width: 300px;
  height: 250px;
  justify-content: center;
  align-items: center;
}

.div-banner-home-mobile-interface {
  display: none;
}

.div-banner-home-mobile-content {
  display: none;
}

.div-banner-home-mobile-footer {
  display: none;
}

@media (max-width: 1390px) {
  .div-search-result-header-content {
    /* background-color: #FFFFFF; */
    display: flex;
    flex-direction: row;
    width: 1120px;
    height: 100%;
  }

  .div-search-result-header {
    /* background-color: #FFFFFF; */
    display: flex;
    width: 100%;
    align-self: center;
    justify-self: center;
   }
}

@media (max-width: 1055px) {
  .div-ad-banner-filters{
    display: none;
  }

  .div-ad-banner-search{
    display: none;
  }

  .div-body-fiters-element {
    display: flex;
    width: 100%;
    height: fit-content;
    flex-direction: row;
    padding-left: 8px;
  }


  .div-right-content-mobile {
    display: flex; 
    flex: 0.25;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    margin-left: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    flex-direction: column;
 }
  
 .div-mid-body-inline {
  display: flex;
  /* flex: 1; */
  /* background-color: rgb(0, 119, 174); */
  width: 90%;
  justify-content: center;
  align-items: center;
  height: fit-content;
  min-height: 120px;
  margin-top: 20px;
}

.div-result-message {
  background-color: #6FCF97;
  display: flex; 
  position: relative;
  width: 90%;
  height: 70px;
  border: 1px solid #ffffff00;
  border-radius: 10px;
  margin-top: 20px;
  justify-content: center;
}

.animation-result-message {
  position: absolute;
  top: 20;
  margin-top: 10px;
  left: calc(20% - 55px);
  animation-name: slideRight;
  animation-duration: 0.6s; 
  animation-delay: 1s; 
  animation-fill-mode: forwards;
}

@keyframes slideRight {
  from {
    left: calc(50% - 35px);
  }
  to {
    left: calc(100% - 45px); 
  }
}

.text-result-message {
  display: flex;
  opacity: 0; 
  flex-direction: row;
  width: 70%;
  color: #ffffff;
  font-weight: normal;
  font-size: 20px;
  font-family: 'Nunito-Bold';
  align-items: center;
  animation: fadeIn 1s 1s;
  animation-fill-mode: forwards;
}

.text-iata-fligts-filters-up {
  font-size: 18px;
  font-family: 'Nunito-Bold';
  color: #333333;
}

.icon-departure-fligts-filters-up {
  display: flex;
  font-size: 20px;
  color: #2D9CDB;
}

.icon-return-fligts-filters-up {
  display: flex;
  font-size: 20px;
  color: #2F80ED;
}

.text-result-message-top {
  display: flex;
  opacity: 0; 
  flex-direction: row;
  width: 90%;
  color: #ffffff;
  font-weight: normal;
  font-size: 18px;
  font-family: 'Nunito-Bold';
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  animation: fadeIn 1s 1s;
  animation-fill-mode: forwards;
}

.div-banner-home-desktop-interface {
  display: none;
}

.div-banner-home-desktop-footer {
  display: none;
}

.div-banner-home-desktop-contents {
  display: none;
}

.div-banner-home-mobile-interface {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 25px;
}

.div-banner-home-mobile-content {
  display: flex;
  width: fit-content;
  height: fit-content;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 25px;
}

.div-banner-home-mobile-footer {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 25px;
}

.img-video-voopter-grid {
  display: flex;
  cursor: pointer;
  width: 90%;
}

}

@media (max-width: 1200px) {
  .voopter-grid {
    display: flex;
    min-width: 100%; 
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    /* background-color: #650087; */
  }
}

@media (max-width: 1390px) {
  .div-right-content {
    display: none;
    flex: 0.25;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: fit-content;
  }

  .div-right-content-mobile {
     display: flex; 
     flex: 0.25;
     width: 300px;
     justify-content: flex-start;
     align-items: center;
     height: 100%;
     margin-left: 20px;
     margin-top: 20px;
     margin-bottom: 20px;
     flex-direction: column;
  }

  .div-voopter-grid {
    background-color: #edf0f2;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding-top: 0px;
    padding-bottom: 30px;
  }
  
}
@media (max-width: 1054px) {
  
  .text-no-filtered-flights{
    color: #ffffff;
    font-weight: normal;
    font-size: 20px; 
    font-family: 'Nunito-Regular';
  }

  .div-left-body-content {
    display: none;
    flex: 0.25;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .div-right-content {
    display: none;
    flex: 0.25;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: fit-content;
  }
  

  .div-mid-body-content {
    display: flex;
    flex: 1;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex-direction: column;
  }

    /* Pagination Styles */

    .div-pagination-container {
      /* background-color: #333; */
      display: flex;
      width: 90%;
      flex-direction: column;
    }
  
    .div-pagination-container-cards {
      /* background-color: #333;   */
      display: 'flex';
      flex-direction: 'column';
      height: 1000;
      width: 100%;
      overflow-y: 'auto';
    }

  .paginationContainer {
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    list-style-type: none;
    padding: 0;  
  }
  .page-link {
    display: inline-block;
    padding: 5px 10px;
    margin: 5px;
    /* width: 20px; */
    cursor: pointer;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    color: #333;
    text-decoration: none;
  }

  .active {
    /* display: flex; */
    /* color: rgb(255, 255, 255); */
    background-color: #002087;
    border-radius: 5px;
    /* width: 22px;
    height: 22px;
    /* border-radius: 50%; */
    justify-content: center; 
  }
  
  .card-grid {
    /* background-color: #650087; */
    display: grid;
    margin-top: 40px;
    width: 90%;
    max-width: 1200px;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 15px;
  
  }
  
  .div-filter-button-mobile {
    /* background-color: #650087; */
    width: 90%;
    display: flex;
    flex-direction: row;  
    margin-top: 20px;
    justify-content: flex-end;
    align-items: center;
  }

  .div-icon-filter-mobile {
    display: flex;
    margin-right: 5px;
    margin-bottom: 2px;
  }
  
  .text-filter-button-mobile {
    color: #00688d;
    font-weight: normal;
    font-size: 20px; 
     font-family: 'Nunito-Regular';
    cursor: pointer;
    margin-right: 20px;
   
  }

  .div-right-content-mobile {
    display: flex; 
    flex: 0.25;
    width: 300px;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    margin-left: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    flex-direction: column;
 }  
 
 .div-fligts-filters-up {
  /* background-color: #890000; */
  display: flex;
  flex-direction: row;
  width: 100%;
  height: fit-content;
  justify-content: center;
  align-items: center;
}

.div-progress-bar-result-message {
  /* background-color: #0d30b1; */
  display: flex;
  width: 98%;
  height: 70px;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
}


}

@media (max-width: 1110px) {
  .card-grid {
    /* background-color: #650087; */
    display: grid;
    margin-top: 40px;
    width: 90%;
    max-width: 1200px;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 15px;
  
  }
  
  .div-right-content-mobile {
    display: flex; 
    flex: 0.25;
    width: 300px;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    margin-left: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    flex-direction: column;
 }
}

@media (max-width: 530px) {
  .text-result-message-top {
    display: flex;
    opacity: 0; 
    flex-direction: row;
    width: 90%;
    color: #ffffff;
    font-weight: normal;
    font-size: 15px;
    font-family: 'Nunito-Bold';
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    animation: fadeIn 1s 1s;
    animation-fill-mode: forwards;
  }
  
  .text-result-message-bottom {
    display: flex;
    opacity: 0; 
    flex-direction: row;
    width: 90%;
    color: #ffffff;
    font-weight: normal;
    font-size: 15px;
    font-family: 'Nunito-Regular';
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    animation: fadeIn 1s 1s;
    animation-fill-mode: forwards;
  }
}

@media (max-width: 490px) {
  .img-phones-voopter-grid {
    display: flex;
    width: 90%;
  }

  .div-apps-voopter-grid {
    /* background-color: #650087;*/
    display: flex;
    flex-direction: column;
    width: 90%;
    justify-items: center;
    height: 300px;
  }


  .div-store-apps-ios {
    display: flex;
    background-color: #FFCC00;
    flex-direction: row;
    height: 40px;
    width: 40px;
    border-radius: 100%;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover{
      background-color: rgb(255, 218, 72);
    }
  }
  
  .div-store-apps-android {
    display: flex;
    background-color: #FFCC00;
    flex-direction: row;
    height: 40px;
    width: 40px;
    border-radius: 100%;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
    cursor: pointer;

    &:hover{
      background-color: rgb(255, 218, 72);
    }
  }

  .div-video-voopter-grid {
    /* background-color: #650087; */
    display: flex;
    flex-direction: column;
    width: 90%;
    justify-items: center;
  }

  .div-right-content-mobile {
    display: flex; 
    flex: 0.25;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    margin-left: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    flex-direction: column;
 }
}

@media (max-width: 420px) {
  .text-result-message-top {
    display: flex;
    opacity: 0; 
    flex-direction: row;
    width: 90%;
    color: #ffffff;
    font-weight: normal;
    font-size: 13px;
    font-family: 'Nunito-Bold';
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    animation: fadeIn 1s 1s;
    animation-fill-mode: forwards;
  }
  
  .text-result-message-bottom {
    display: flex;
    opacity: 0; 
    flex-direction: row;
    width: 90%;
    color: #ffffff;
    font-weight: normal;
    font-size: 13px;
    font-family: 'Nunito-Regular';
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    animation: fadeIn 1s 1s;
    animation-fill-mode: forwards;
  }
}

@media (max-width: 400px) {
  .text-no-filtered-flights{
    color: #ffffff;
    font-weight: normal;
    font-size: 15px; 
    font-family: 'Nunito-Regular';
  }

  .div-no-filtered-flights {
    /* color: rgb(255, 0, 0); */
    display: flex;
    width: 100%;
    height: auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 10;
    padding-right: 10;
  }

  .div-progress-bar-result-message {
    /* background-color: #0d30b1; */
    display: flex;
    width: 100%;
    height: 70px;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .div-result-message {
    background-color: #6FCF97;
    display: flex; 
    position: relative;
    width: 90%;
    height: 70px;
    min-height: 50px;
    border: 1px solid #ffffff00;
    border-radius: 10px;
    margin-top: 20px;
    justify-content: center;
    align-items: center;
  }
  
  .text-result-message-top {
    display: flex;
    opacity: 0; 
    flex-direction: row;
    width: 90%;
    color: #ffffff;
    font-weight: normal;
    font-size: 13px;
    font-family: 'Nunito-Bold';
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    animation: fadeIn 1s 1s;
    animation-fill-mode: forwards;
  }
  
  .text-result-message-bottom {
    display: flex;
    opacity: 0; 
    flex-direction: row;
    width: 90%;
    color: #ffffff;
    font-weight: normal;
    font-size: 13px;
    font-family: 'Nunito-Regular';
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    animation: fadeIn 1s 1s;
    animation-fill-mode: forwards;
  }

  .text-result-message {
    display: flex;
    opacity: 0; 
    flex-direction: row;
    width: 50%;
    color: #ffffff;
    font-weight: normal;
    font-size: 14px;
    font-family: 'Nunito-Bold';
    align-items: center;
    animation: fadeIn 1s 1s;
    animation-fill-mode: forwards;
  }
}

@media (max-width: 369px) {
  .text-result-message-top {
    display: flex;
    opacity: 0; 
    flex-direction: row;
    width: 90%;
    color: #ffffff;
    font-weight: normal;
    font-size: 11px;
    font-family: 'Nunito-Bold';
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    animation: fadeIn 1s 1s;
    animation-fill-mode: forwards;
  }
  
  .text-result-message-bottom {
    display: flex;
    opacity: 0; 
    flex-direction: row;
    width: 90%;
    color: #ffffff;
    font-weight: normal;
    font-size: 11px;
    font-family: 'Nunito-Regular';
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    animation: fadeIn 1s 1s;
    animation-fill-mode: forwards;
  }
}


@media (max-width: 350px) {

  .card-grid {
    /* background-color: #650087; */
    display: grid;
    margin-top: 40px;
    width: 90%;
    max-width: 1200px;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 15px;
  
  }


  .div-no-filtered-flights {
    /* color: rgb(255, 0, 0); */
    display: flex;
    width: 100%;
    height: auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 10;
    padding-right: 10;
  }

  .div-fligts-filters-up {
    /* background-color: #890000; */
    display: flex;
    flex-direction: row;
    width: 100%;
    height: fit-content;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 350px) {

    .animation-result-message {
      display: none;
    }
  }
  
}

/* Define o azul do site para os títulos */
.general-tips h1,
h2 {
  color: #0b6fae;
  /* Substitua esta cor pelo azul exato do site */
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

/* Define o alinhamento do conteúdo */
.general-tips {
  max-width: 1000px;
  /* Largura máxima alinhada com o buscador */
  margin: 0 auto;
  /* Centraliza o conteúdo */
  padding: 0 20px;
  /* Adiciona espaçamento lateral */
}

/* Estilo para os quadros ao redor do texto */
.general-tips .content-box {
  background-color: #ffffff;
  /* Fundo branco */
  border: 1px solid #0b6fae;
  /* Borda da cor do site */
  padding: 5px 20px 5px 20px;
  /* Espaçamento interno */
  margin-bottom: 40px;
  /* Espaçamento inferior para separar seções */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* Leve sombra para destaque */
}

/* Estilo para os parágrafos */
.general-tips .content-box p {
  font-size: 16px;
  line-height: 1.6;
  color: #333;
  /* Cor do texto */
  margin-bottom: 20px;
}


@media screen and (max-width: 1055px) {
.title-text-offers-tips {

    color: #fff;
    font-weight: normal;
    font-size: 35px;
    font-family: 'Nunito-Regular';
    margin: 0 auto;
    text-align: center;
  }
}

.intro-container {
  min-width: 1000px;
  max-width: 1000px;
  margin: 40px auto;
  padding: 0 20px;
  color: #161616;
}


.intro-title{
  -webkit-text-size-adjust: 100%;
    --initial-vh: 9.11px;
    --vh: 9.11px;
    font-family: inherit;
    box-sizing: inherit;
    margin: 0;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.75rem;
    padding-bottom: .5rem;

}

.intro-section{
      box-shadow: inset 0 -1px 0 0 #c1c7cf;
}



.intro-section-components{
      -webkit-text-size-adjust: 100%;
        --initial-vh: 9.11px;
        --vh: 9.11px;
        font: inherit;
        text-transform: none;
        cursor: pointer;
        text-align: left;
        box-sizing: inherit;
        display: inline-flex;
        flex-direction: row;
        margin: 1rem 0;
        width: 100%;
}
.intro-section-title{
      -webkit-text-size-adjust: 100%;
        --initial-vh: 9.11px;
        --vh: 9.11px;
        font: inherit;
        text-transform: none;
        cursor: pointer;
        text-align: left;
        box-sizing: inherit;
        flex-grow: 1;
}

.intro-section-title h1, h2, h3{
      -webkit-text-size-adjust: 100%;
        --initial-vh: 9.11px;
        --vh: 9.11px;
        font: inherit;
        text-transform: none;
        cursor: pointer;
        text-align: left;
        box-sizing: inherit;
        margin: 0;
        font-size: 1rem;
        font-weight: 700;
        line-height: 1.25rem;
        color:inherit;
}
.intro-section-arrow span{
      -webkit-text-size-adjust: 100%;
        --initial-vh: 9.11px;
        --vh: 9.11px;
        font: inherit;
        text-transform: none;
        cursor: pointer;
        text-align: left;
        box-sizing: inherit;
        display: inline-block;
}

.intro-section-content{
      -webkit-text-size-adjust: 100%;
        --initial-vh: 9.11px;
        --vh: 9.11px;
        font-size: 1rem;
        line-height: 1.3rem;
        font-family: RelativeSet, Skyscanner Relative, -apple-system, BlinkMacSystemFont, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
        box-sizing: inherit;
        padding-bottom: 1rem;
}

@media screen and (max-width: 1055px) {

  .intro-container {
    color: #fff;
    margin: 40px auto;
    padding: 0 20px;
    min-width: auto;
  }

  .intro-section {
    border-bottom: 1px solid #c1c7cf;
    box-shadow: none;
  }

  .intro-section-arrow span {
    fill: #fff;
  }

}