.div-count-button{
  background-color: #F9F9F9;
  display: flex;
  flex-direction: row;
  width: 90px;
  height: 50px;
  justify-content: center;
  align-items: center;
  border: 1px solid #9A9A98;
  border-radius: 10px;
}

.div-one-plus {
  display: flex;
  width: 40%;
  height: 100%;
  justify-content: center;
  align-items: center;
  cursor: pointer;

}
.div-one-less {
  display: flex;
  width: 40%;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.div-count-button-value {
  display: flex;
  width: 20%;
  height: 100%;
  justify-content: center;
  align-items: center;
}


.text-count-button-value {  
  font-size: 17px;
  font-family: 'Nunito-Bold';
  color: #00A9D6;
  
}


@media (max-width: 440px) {

}


