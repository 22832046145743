.div-button-group {
  background-color: #ffffff;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 60px;
  border-radius: 10px;
  cursor: pointer;
  flex-direction: row;
  border: 2px solid #00688D;
}

.div-button-group-left {
  /* background-color: #0000ff; */
  display: flex;
  width: 50%;
  justify-content: center;
  align-items: center;
  height: 100%;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  cursor: pointer;
  flex-direction: row;
}

.div-button-group-right {
  background-color: #00688D;
  display: flex;
  width: 50%;
  justify-content: center;
  align-items: center;
  height: 100%;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  cursor: pointer;
  flex-direction: row;
}

.text-button-group-left {
  font-family: 'Nunito-Bold';
  font-size: 20px;
  color: #9A9A98;
}

.text-button-group-right {
  font-family: 'Nunito-Bold';
  font-size: 20px;  
  color: #F9F9F9;
}