
.div-select-button {
  display: flex;
  /* aspect-ratio: 1/1; */
  border-radius: 50px;
  cursor: pointer;
  border: 2px solid #00688D;
  /* width: auto; */
  min-width: fit-content;
  height: 30px;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 4px;
  padding-bottom: 4px;
  background-color: #FFFFFF;
  gap: 6px;
  outline: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

/* .div-select-button:focus {
  outline: none;

} */

.text-select-button {
  font-size: 16px;
  font-family: 'Nunito-Regular';
  color: #FFFFFF;
}

