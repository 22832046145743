.main-banner {
  position: relative;
  margin-left: 10px;
  height: 100%;
}

.main-banner button {
  height: 100%;
  width: 480px;
  background-color: #35bde8;
  cursor: pointer;
  color: #ffffff;
  border: none;
  display: flex;
  border-radius: 0 0 0 32px;
  font-family: 'Nunito-Regular';
  font-size: 16px;
}

.main-banner .content-banner {
  text-align: left;
  margin: auto;
  margin-left: 20px;
  margin-bottom: 30px;
  width: 262px;
}

.main-banner .content-banner a {
  font-family: 'Nunito-Bold';
  cursor: pointer;
  color: #ffffff;
}

.main-banner  img {
  max-height: 118px;
  right: 0;
  position: absolute;
}

@media (max-width: 1290px) {  
  .main-banner {
    margin-left: 5px;
  }

  .main-banner button {
    width: 340px;
    font-size: 12px;
  }

  .main-banner .content-banner {
    margin-left: 14px;
    width: 210px;
  }

  .main-banner  img {
    max-height: 116px;
    right: -32px;
    bottom: 0;
  }
}