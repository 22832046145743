.nav-item {
  margin: 0;
  padding: 0;
  color: #5c5c5c;
  text-transform: uppercase;
  font-weight: normal;
  font-size: 13px;
  border: 1px solid #fff;
  padding: 5px 10px;
  border-radius: 5px;
  font-family: 'Nunito-Regular';
  position: relative; /* Necessário para posicionar o efeito ripple */
  overflow: hidden; /* O efeito ripple precisa ser contido dentro do botão */
  transition: all 0.3s;
}

.nav-item:hover {
  cursor: pointer;
  color: #00aad6; 
  border-color: #00aad6;
}

@media (max-width: 1054px) {
  .nav-item {
    display: flex;
    margin: 20px 0;
    padding: 0;
    color: #fffefe;
    text-transform: uppercase;
    font-weight: normal;
    font-size: 120%;
    border: 1px solid #ffffff00;
    border-radius: 5px;
    font-family: 'Nunito-Bold';
    font-weight: bold;
    position: relative; /* Necessário para o efeito ripple */
    overflow: hidden; /* Contém o efeito dentro do botão */
  }

  .nav-item:hover {
    color: #fffefe; 
  }

  /* Efeito ripple */
  .ripple {
    position: absolute;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.4);
    transform: scale(0);
    animation: ripple-animation 0.6s linear;
    pointer-events: none;
  }

  @keyframes ripple-animation {
    to {
      transform: scale(4);
      opacity: 0;
    }
  }
}
