.div-logo{
  display: flex;
  flex-direction: row;
  height: 90%;
  min-width: 250px;
  overflow: hidden;
  justify-content: center;
}

.div-logo-img {
  width: 150px; 
  height: 100%; 
  object-fit: fill; 
  cursor: pointer;
}

@media (max-width: 1054px) {
  .div-logo-img {
    width: 130px;
    cursor: pointer;
  }
}
