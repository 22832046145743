.div-principal {
  margin-right: 10px;
  display: flex;
}

.div-radiobutton {
  display: flex;
  aspect-ratio: 1/1;
  border-radius: 8px;
  cursor: pointer;
  border: 2px solid #00688D;
  justify-content: center;
  align-items: center;
  padding-left: 1px;
  padding-right: 1px;
  padding-top: 3px;
}


.label-radiobutton {
  color: #ffffff;
  font-weight: 600;
  font-size: 16px; 
  margin-top: 2px;
  font-family: 'Nunito-Regular';
  cursor: pointer;
  margin-left: 4px;
}