.icon-mobile-menu{
  display: none;
}

@media (max-width: 1054px) {
  .div-button-mobile-menu {
    display: flex;
    width: 38px; 
    height: 38px; 
    background-color: #1dc2ec;
    position: absolute;
    left: 0;
    top: 0;
    margin-left: 10px;
    transform: translateY(70%); 
    border-radius: 50%;     
    justify-content: center;
    align-items: center; 

    &:hover {
      cursor: pointer;
      background-color: #1dc2ec;
    }
  }

  .icon-mobile-menu{
    display: flex;
    font-size: 25px;   
    color: #fff;
    margin-right: 1px;
    margin-bottom: 2px;
  }
}
