
.div-flight-card-skeleton-mobile {
  background-color: #FFFFFF;
  display: flex;
  width: 88%;
  height: 190px;
  border-radius: 12px;
  margin-top: 20px;
  padding-top: 20px;
  padding-bottom: 15px;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.div-flight-card-skeleton-item {
  /* background-color: #a81515; */
  display: flex;
  width: 100%;
  height: fit-content;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.skeleton-loading {
  background-color: hsl(0, 0%, 57%);
}



