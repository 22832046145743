.div-flight-card {
  /* background-color: #7a0e0e; */
  display: flex;
  flex-direction: column;
  /* width: 90%; */
  height: auto;
  margin-top: 20px;
  width: 700px;
  border-radius: 12px;
}

.div-item-flight-card {
  background-color: #fdfdfd; 
  width: 100%;
  justify-content: center;
  align-items: center;
  height: auto;
  border-radius: 12px;
  cursor: pointer;
  border: 1px solid #c8c8c8;
  box-shadow: 0 0 10px rgba(170, 170, 170, 0.3);

  &:hover{
    box-shadow: 0 0 10px rgba(4, 196, 231, 0.562);

  }
}


.div-item-flight-card-oneway {
  background-color: #fdfdfd; 
  width: 100%;
  justify-content: center;
  align-items: center;
  height: auto;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid #c8c8c8;
  box-shadow: 0 0 10px rgba(170, 170, 170, 0.3);

  &:hover{
    box-shadow: 0 0 10px rgba(4, 196, 231, 0.562);

  }
}

.div-flight-preview {
  /* background-color: #a30e99; */
  display: flex;
  width: 100%;
  /* height: 150px; */
}

.div-left-flight-preview {
  /* background-color: #180ea3; */
  display: flex;
  flex: 0.7;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.div-right-flight-preview {
  /* background-color: #0e88a3; */
  display: flex;
  flex: 0.3;
  height: 100%;
  justify-content: center;
  align-items: center;
  border-left: 1px solid #b3b3b385;
  flex-direction: column;
}

.flight-component {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
}


.div-image-flight-component {
  /* background-color: #0e88a3;   */
  display: flex;
  flex: 0.2;
  height: 100%;
  justify-content: flex-end;
  align-items: center;
}

.image-flight {
  display: flex;
  width: 85px;
}

.div-leg-component {
  /* background-color: #150ea3; */
  display: flex;
  flex: 0.8;
  height: 100%;
  justify-content: flex-end;
  align-items: center;
  /* padding-left: 0px; */
}

.div-leg-date {
  /* background-color: #0e6fa3; */
  display: flex;
  flex: 0.2;
  height: 50%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid #fff;
}


.text-leg-day-week {

  font-size: 12px;
  font-family: 'Nunito-Regular';
}


.text-leg-day {
  color: #000000;
  font-size: 18px;
  font-family: 'Nunito-Bold';
}

.div-leg-data {
  /* background-color: #0ea36f; */
  display: flex;
  /* flex: 0.8; */
  width: 75%;
  height: fit-content;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.div-leg-data-up {
  /* background-color: #0e38a3; */
  display: flex;
  flex: 0.4;
  width: 95%;
  flex-direction: row;
  justify-content: center;
  /* justify-content: center;
  align-items: center;
  flex-direction: column; */
}

.div-leg-data-up-left {
  /* background-color: #940ea3; */
  display: flex;
  flex-direction: column;
  flex: 0.21;
  height: 100%;
  align-items: center;
  justify-content: flex-end;
}

.div-leg-data-up-mid {
  /* background-color: #97a30e; */
  display: flex;
  /* flex: 0.2; */
  width: 40%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.div-leg-duration {
  /* background-color: #0e92a3; */
  display: flex;
  flex: 0.2;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
}


.text-duration{
  vertical-align: flex-end;
  color: rgb(45, 45, 45);
  /* font-size: 18px; */
   font-family: 'Nunito-Regular';
}

.div-leg-stops {
  /* background-color: #a30e24; */
  display: flex;
  /* flex: 0.2; */
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.div-leg-stops-line {
  display: flex;
  position: relative;
  background-color: #F2994A;
  display: flex;
  width: 70%;
  height: 2px;
  margin-right: 5px;
  justify-content: center;
}

.icon-leg-stops-line {
  color: #000000;
  font-size: 10px;
}

.div-leg-stops-dot-div {
  /* background-color: rgb(194, 194, 194); */
  display: flex;
  height: 10px;
  position: absolute;
  justify-content: space-between;
  margin-left: 5px;
  top: -4px;
}

.div-leg-stops-dot {
  display: flex;
  width: 10px;
  height: 10px;
  background-color: #F2994A;
  border-radius: 50%;
}


.div-leg-data-up-right{
  /* background-color: #22a30e; */
  display: flex;
  flex: 0.21;
  height: 100%;
  align-items: flex-start;
  justify-content: flex-end;
}

.div-leg-data-down-left {
  /* background-color: #0e85a3; */
  display: flex;
  flex: 0.21;
  height: 100%;
  align-items: center;
  justify-content: flex-end;
  /* justify-content: center;
  align-items: center;
  flex-direction: column; */
}

.div-text-time {
  display: flex;
}

.text-time {
  color: #000000;
  font-size: 17px;
  font-family: 'Nunito-Regular';
}

.text-difference-between-dates {
  display: flex;
  margin-left: 3px;
  padding-top: 2px;
}


.text-airport {
  color: #000000;
  font-size: 15px;
   font-family: 'Nunito-Regular';
}


.div-leg-data-down {
  /* background-color: #a3290e; */
  display: flex;
  flex: 0.2;
  width: 100%;
  /* justify-content: center;
  align-items: center;
  flex-direction: column; */
}

.div-leg-data-down-mid {
  /* background-color: #97a30e; */
  display: flex;
  flex: 0.5;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.text-direct-stop {
  color:rgb(0, 32, 135);
  font-size: 12px;
   font-family: 'Nunito-Regular';
}

.div-leg-data-down-right {
  /* background-color: #0e85a3; */
  display: flex;
  flex: 0.21;
  height: 100%;
  align-items: center;
  justify-content: flex-start;
  /* justify-content: center;
  align-items: center;
  flex-direction: column; */
}


.button-flight-offer {
  display: flex;
  width: 90%;
  height: 50px; 
  color: aliceblue;
  /* background-color: rgb(0, 32, 135); */
  background-color: #ffcc00;
  cursor: pointer;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
  border: none;
  justify-content: center;
  align-items: center;
  margin-top: 0px;
}


.div-right-arrow {
  display: flex;
  margin-top: 1px;
  margin-left: 5px;
}

.button-text-offer{
  color: #414141;
  font-size: 15px;
  font-family: 'Nunito-Bold';
}

.text-price {
  color: rgb(46, 46, 46);
  font-size: 30px;
  font-family: 'Nunito-Bold';
  /* font-weight: bold; */
}

.text-agent {
  color: rgb(46, 46, 46);
  font-size: 15px;
  font-family: 'Nunito-Bold';
  margin-bottom: 5px;
  /* font-weight: bold; */
}


.div-flight-details {
  /* background-color: #1a0ea3; */
  /* border-top: 1px solid #b3b3b385; */
  display: flex;
  width: 100%;
  height: auto;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.div-flight-details-footer {
  /* background-color: #1a0ea3; */
  display: flex;
  flex-direction: row;
  width: 90%;
  height: 50px;
  margin-top: 10px;
  align-items: center;
  justify-content: space-between;
}

.div-flight-details-footer-left {
  /* background-color: #1a0ea3; */
  display: flex;
  flex-direction: row;
  width: fit-content;
  height: fit-content;
}

.div-icon-flight-details-footer-left {
  /* background-color:  #e80ed6; */
  display: flex;
  margin-left: 20px;
  justify-content: center;
  align-items: center;
  /* height: 100%;
  aspect-ratio: 1/1; */
}

.text-flight-details-footer-left {
  color: #EB5757;
  font-size: 12px;
  font-family: 'Nunito-Bold'
}

.icon-flight-details-footer-left {
  color: #EB5757;
  font-size: 26px;
}

.div-flight-details-footer-right {
  display: flex;
  flex-direction: row;
  background-color: #F2C94C;
  height: 60%;
  width: 52%;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
}

.text-flight-details-footer-right {
  color: #333333; 
  font-size: 12px;
  font-family: 'Nunito-Bold';
  margin-right: 5px;
}


.div-flight-details-item {
  /* background-color:  #e80ed6; */
  display: flex;
  width: 90%;
  height: auto;
  flex-direction: column;
  border-radius: 10px;
  border: 1px solid #b3b3b385;
  padding-bottom: 20px;
}

.header-details-item {
  /* background-color:  #e80ed6; */
  display: flex;
  width: 100%;
  height: fit-content;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #b3b3b385;
}

.header-details-date {
  color: rgb(46, 46, 46);
  font-size: 17px;
  font-family: 'Nunito-Bold';
  font-weight: bold;
  margin-left: 20px;
}

.header-details-duration{
  color: rgb(46, 46, 46);
  font-size: 17px;
  font-family: 'Nunito-Bold';
  font-weight: bold;
  margin-right: 20px;
}

.div-body-details {
  /* background-color:  #94e80e; */
  display: flex;
  width: 100%;
  height: auto;
  flex-direction: column;
  align-items: center;
  align-self: center;
  margin-top: 0px;
}

.div-body-details-item {
  /* background-color:  #cb0ee8; */
  display: flex;
  width: 95%;
  height: fit-content;
  flex-direction: column;
  align-items: center;
  align-self: center;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.div-details-item-down {
  /* background-color:  #9f0ee8; */
  display: flex;
  flex-direction: row;
  width: 100%;
  height: fit-content;
  /* align-items: center; */
  /* margin-top: 10px; */
  /* align-items: center; */

}


.div-details-item-up {
  /* background-color:  #0e20e8; */
  display: flex;
  width: 100%;
  height: 50px;
  flex-direction: row;
  align-items: center;
}

.div-image-flight-number {
  display: flex;
  align-items: center;
  width: 100%;
  margin-right: 10px;
}


.image-airline-item-up {
  height: 30px;
  object-fit: fill; 
  margin-right: 10px;
}


.text-flight-number-item-left {
  color: rgb(46, 46, 46);
  font-size: 12px;
   font-family: 'Nunito-Regular';
  margin-left: 7px;
}

.text-flight-number-item-right {
  color: rgb(46, 46, 46);
  font-size: 12px;
  font-family: 'Nunito-Bold';
  margin-left: 3px;
}


.div-line-conection {
  /* background-color:  #0eb5e8; */
  display: flex;
  flex-direction: column;
  flex:0.1;
  height: 100%;
  align-items: center;
  justify-items: center;
  justify-content: center;
  /* align-items: center; */
}


.dot-conection {
  height: 10px;
  aspect-ratio: 1/1;
  /* border: 2px solid #a9a9a9; */
  border-radius: 50%;
  background-color: #F2994A;
} 

.line-conection {
  width: 1px;
  height: 100%;
}

.div-conections {
  /* background-color:  #0ee8c7; */
  display: flex;
  flex-direction: column;
  /* flex:1; */
  height:fit-content;
  justify-content: space-between;
  /* align-items: center; */
  /* justify-items: center;
  justify-content: space-between; */
}
.div-conections-top-content {
  display: flex;
  flex-direction: row;
  height: fit-content;
  /* margin-bottom: 5px; */
}

.text-time-conections {
  color: rgb(45, 45, 45);
  font-size: 15px;
  font-family: 'Nunito-Bold';
}

.text-place-conections {
  color: rgb(45, 45, 45);
  font-size: 15px;
  font-family: 'Nunito-Regular';
  /* white-space: nowrap;      
  overflow: hidden;         
  text-overflow: ellipsis;  
  width: 100%;           */
}

.text-duration{
  vertical-align: flex-end;
  color: rgb(45, 45, 45);
  font-size: 11px;
   font-family: 'Nunito-Regular';
}

.text-duration-connection{
  color: #828282;
  font-size: 13px;
  font-family: 'Nunito-Regular';
  margin-top: 5px;
  margin-bottom: 5px;
}


.div-stop-division {
  /* background-color: #eaeaea; */
  display: flex;
  width: 96%;
  border-radius: 10px;
  align-items: center;
  justify-self: center;
  margin-top: 15px;
  /* margin-left: 30px; */
}


.div-arrow-conections {
  /* background-color:  #190ee8; */
  display: flex;
  flex-direction: column;
  flex:0.08  ;
  height: 100%;
  align-items: center;
  justify-items: center;
  justify-content: space-between;
}


.icon-arrow-conections {
  font-size: 24px;
}


.div-airports-names-stops {
  /* background-color:  #e8e80e; */
  display: flex;
  flex-direction: column;
  flex:0.8;
  height: 100%;
  justify-content: space-between;
  /* align-items: center; */
  /* align-items: center; */
}

.text-airport-name {
  color: rgb(45, 45, 45);
  font-size: 18px;
   font-family: 'Nunito-Regular';
  margin-left: 0px;
}


.icon-clock-time-stopped{
  font-size: 18px;
  margin-right: 10px;
}

.text-time-stopped{
  display: flex;
  color: #EB5757;
  font-size: 13px;
  font-family: 'Nunito-Regular';
  margin-left: 20px;
}

.dot-clock-time-stopped{
  display: flex;
  height: 6px;
  aspect-ratio: 1/1;
  background-color: #EB5757;
  border-radius: 50%;
  align-self: center;
  margin-right: 8px;
}


.div-sellers-details {
  /* background-color:  #94e80e; */
  display: flex;
  width: 100%;
  height: auto;
  flex-direction: column;
  align-items: center;
  align-self: center;
  margin-bottom: 20px;
  margin-top: 20px;
}

.div-sellers-details-item {
  /* background-color:  #e80ed6; */
  display: flex;
  width: 90%;
  height: auto;
  flex-direction: column;
  border-radius: 10px;
  align-items: center; 
  justify-content: center;
  
}

.div-seller {
  /* background-color: rgb(2, 131, 88);*/
  display: flex;  
  flex-direction: row;
  width: 95%;
  height: 55px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  /* padding-top: 10px;
  padding-bottom: 10px; */
  /* border-bottom: 1px solid #b3b3b385; */
}



.image-seller {
  height: 20px;
  overflow: hidden;
  object-fit: contain;
}

.div-left-seller {
  background-color: #FFFFFF;
  display: flex;
  flex-direction: row;
  width: 50%;
  height: 100%;
  align-items: center;
  border: 1.5px solid #219653;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  justify-content: center;
  gap: 30%;

}

.image-seller {
  height: 30px;
  overflow: hidden;
  object-fit: contain;
}

.div-right-seller {
  background-color: #219653;
  border: 1.5px solid #219653;
  display: flex;
  flex-direction: row;
  width: 50%;
  height: 100%;
  align-items: center;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.text-price-seller {
  display: flex;
  margin-right: 20px;
  font-family: 'Nunito-Bold';  
}


.button-seller {
  display: flex;
  width: 160px;
  height: 100%;
  object-fit: fill; 
  cursor: pointer;
  color: #fff;
  background-color: #2ecc71;
  border-radius: 5px;
  border: none;
  justify-content: center;
  align-items: center;
  font-family: 'Nunito-Bold';  
  font-weight: bolder;
  flex-direction: row;

  &:hover{
    background-color: #219c54;
  }
}

.div-button-seller-text {
  display: flex;
  width: 75%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;  
  margin-left: 10px;  

  /* background-color: #21219c; */
}



.div-button-seller-icon {
  display: flex;
  width: 15%;
  height: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  /* padding-right: 5px; */
  /* align-items: center; */
  /* background-color: #799c21; */
}

.icon-button-seller {
  display: flex;
  font-size: 30px;
  color: #FFFFFF;
}

.div-separator-seller{
  display: flex;
  width: 100%;
  height: 1px;
  background-color: #b3b3b385;
}

.icon-flight-card {
  font-size: 25px;
  color: rgb(0, 0, 0);
}

.div-text-price {
  display: flex;
  width: 90%;
  height:30%;
  background-color: #00688D;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-bottom-left-radius: 0px;
  align-items: center;
  justify-content: center;
}

.div-text-price-content {
  display: flex;
}

.div-image-sellers {
  /* background-color: #138d00;   */
  display: flex;
  /* width: 50px; */
  width: 25%;
  /* max-width: 50px; */
  justify-content: flex-start;
}

.div-text-price-content-sellers {
  /* background-color: #1a008d; */
  display: flex;
  width: fit-content;
  justify-content: center;
  margin-right: 0px;
}



.div-dashed-line {
 display: flex;
 width: 100%;
 justify-content: center;
 align-items: center;
}

.dashed-line {
  border-top: 1px dashed #AAAAAA;
  width: 90%;
  margin: 0px 0;
}

.div-right-seller-left-content {
  /* background-color: #7a0e0e; */
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 80%;
  max-width: 80%;
  justify-content: center;
  /* align-items: center; */
  /* padding-left: 15px; */
  /* margin-left: 15px; */
}

.text-right-top-seller-left-content {
  font-family: 'Nunito-Bold';
  color: #FFFFFF;
  font-size: 12px;
  padding-left: 20px;
}

.text-right-bottom-seller-left-content {
  /* background-color: #0e6a7a; */
  font-size: 15px;
  font-family: 'Nunito-Bold';
  text-transform: uppercase;
  color: #FFFFFF;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 85%;
  padding-left: 20px;
  /* max-width: 70%;  */
}

.div-right-seller-right-content {
  /* background-color: #0e6a7a; */
  display: flex;
  height: 100%;
  width: 20%;
  justify-content: center;
  align-items: center;
}

.div-message-before-flight-details {
  /* background-color: #0e6a7a; */
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  justify-self: center;
  width: 90%;
  margin-top: 20px;
}

.text-message-before-flight-details {
  font-family: 'Nunito-Regular';
  font-size: 11px;
  color: #4F4F4F;
}

.div-title-header-details-item {
  display: flex;
  flex-direction: row;
  background-color: #828282;
  width: 100%;
  height: 30px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.icon-header-details-item {
  display: flex;
  color: #FFFFFF;
  align-self: center;
  margin-left: auto;
  margin-right: 12px;
}

.text-title-header-details-item {
  font-family: 'Nunito-Bold';
  font-size: 10px;
  color: #FFFFFF;
  align-self: center;
  padding-left: 15px;
}

.div-header-details-item {
  display: flex;
  flex-direction: row;
  background-color: #E0E0E0;
  width: 100%;
  height: 54px;
}

.div-header-details-item-left {
  /* background-color: #ff0000; */
  display: flex;
  flex-direction: row;
  width: 15%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.div-header-details-item-mid {
  /* background-color: #0400ff; */
  display: flex;
  flex-direction: column;
  width: 60%;
  height: 100%;
  justify-content: center;
}

.div-header-details-item-right {
  /* background-color: #00ff44; */
  display: flex;
  flex-direction: column;
  width: fit-content;
  height: 100%;
  justify-content: center;

}

.text-top-header-details-item-mid-right {
  font-family: 'Nunito-Bold';
  font-size: 12px;
}

.text-bottom-header-details-item-mid-right {
  font-family: 'Nunito-Regular';
  font-size: 12px;
}


.div-leg-date-content {
  display: flex;
  position: relative;
  flex-direction: row;
}

.div-leg-date-content-left{
  display: flex;
  padding-top: 3px;
  /* width: 10px; */
}

.icon-leg-date {
  display: flex;
  font-size: 15px;
  color: #2D9CDB;
}

.div-leg-date-content-right{
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 50px;
}

.text-price-currency {
  color: #FFFFFF;
  font-size: 15px;
  font-family: 'Nunito-Regular';
  margin-top: 2px;
  margin-right: 3px;
}

.text-price {
  color: #FFFFFF;
  font-size: 25px;
  font-family: 'Nunito-Bold';
  font-weight: bold;
}

.text-price-cents {
  color: #FFFFFF;
  font-size: 15px;
  font-family: 'Nunito-Bold';
  font-weight: bold;
  margin-top: 2px;
}

.text-airline-image-error {
  display: flex;
  justify-content: center;
  align-self: center;
  justify-self: center;
  font-family: 'Nunito-Regular';
  font-size: 13px;
  width: 100%;
  padding-left:15px;
  text-align: center;
}
/* 
@media (max-width: 1054px) {
  
} */

/* 440px */
@media (max-width: 1054px) {
  .text-airline-image-error {
    display: flex;
    justify-content: center;
    align-self: center;
    justify-self: center;
    font-family: 'Nunito-Regular';
    font-size: 11px;
    width: 100%;
    padding-left: 0px;
    text-align: center;
  }

  .div-flight-card {
    /* background-color: #7a0e0e; */
    display: flex;
    flex-direction: column;
    /* width: 90%; */
    height: auto;
    margin-top: 20px;
    width: 98%;
    border-radius: 12px;
  }
  
  .div-item-flight-card-oneway {
    background-color: #fdfdfd; 
    width: 100%;
    justify-content: center;  
    align-items: center;
    /* height: 220px; */
    height: auto;
    min-height: 150px;
    border-radius: 12px;
    cursor: pointer;
    border: 1px solid #c8c8c8;
    box-shadow: 0 0 10px rgba(170, 170, 170, 0.3);
  
    &:hover{
      box-shadow: 0 0 10px rgba(4, 196, 231, 0.562);
  
    }
  }

  .div-item-flight-card {
    background-color: #fdfdfd; 
    width: 100%;
    justify-content: center;
    align-items: center;
    /* height: 220px; */
    height: auto;
    min-height: 210px;
    border-radius: 12px;
    cursor: pointer;
    border: 1px solid #c8c8c8;
    box-shadow: 0 0 10px rgba(170, 170, 170, 0.3);
  
    &:hover{
      box-shadow: 0 0 10px rgba(4, 196, 231, 0.562);
  
    }
  }
  
  
  .div-flight-preview {
    /* background-color: #a30e99; */
    display: flex;
    width: 100%;
    flex-direction: column;
    /* height: auto; */
  }
  
  .div-left-flight-preview {
    /* background-color: #180ea3; */
    display: flex;
    flex: 0.5;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  
  .div-right-flight-preview {
    /* background-color: #0e88a3;   */
    display: flex;
    /* flex: 0.3; */
    height: fit-content;
    width: 90%;
    justify-content: flex-end;
    align-items: center;
    border-top: 1px solid #b3b3b385;
    flex-direction: row;
    padding-right: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
    border-radius: 15px;
    align-self: center;
  }
  
  .flight-component {
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  
  
  .div-image-flight-component {
    /* background-color: #0e88a3;   */
    display: flex;
    /* flex: 0.2; */
    width: 45px;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
  
  .image-flight {
    display: flex;
    width: 45px;
  }
  
  .div-leg-component {
    /* background-color: #150ea3; */
    display: flex;
    flex: 0.8;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
  
  .div-leg-date {
    /* background-color: #0e6fa3; */
    display: flex;
    flex: 0.2;
    height: 50%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: 1px solid #fff;
  }
  
  .div-leg-date-content {
    display: flex;
    position: relative;
    flex-direction: row;
  }

  .div-leg-date-content-left{
    display: flex;
    padding-top: 3px;
    /* width: 10px; */
  }

  .div-leg-date-content-right{
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 50px;
  }
  
  .icon-leg-date {
    display: flex;
    font-size: 15px;
    color: #2D9CDB;
  }
  
  .text-leg-day-week {

    font-size: 12px;
    font-family: 'Nunito-Regular';
  }
  
  .text-leg-day {
    color: #000000;
    font-size: 18px;
    font-family: 'Nunito-Bold';
  }
  
  
  .div-leg-data {
    /* background-color: #0ea36f; */
    display: flex;
    /* flex: 0.8; */
    width: 75%;
    height: 80%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .div-leg-data-up {
    /* background-color: #0e38a3; */
    display: flex;
    flex: 0.4;
    width: 95%;
    flex-direction: row;
    justify-content: center;
    /* justify-content: center;
    align-items: center;
    flex-direction: column; */
  }
  
  .div-leg-data-up-left {
    /* background-color: #940ea3; */
    display: flex;
    flex-direction: column;
    flex: 0.21;
    height: 100%;
    align-items: center;
    justify-content: flex-end;
  }
  
  .div-leg-data-up-mid {
    /* background-color: #97a30e; */
    display: flex;
    /* flex: 0.5; */
    height: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  
  .div-leg-duration {
    /* background-color: #0e92a3; */
    display: flex;
    flex: 0.2;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
  }
  
  .text-duration{
    vertical-align: flex-end;
    color: rgb(45, 45, 45);
    /* font-size: 18px; */
     font-family: 'Nunito-Regular';
  }
  
  .div-leg-stops {
    /* background-color: #a30e24; */
    display: flex;
    /* flex: 0.2; */
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: row;
  }
  
  .div-leg-stops-line {
    display: flex;
    position: relative;
    background-color: #F2994A;
    display: flex;
    width: 50%;
    height: 2px;
    margin-right: 1px;
    justify-content: center;
  }
  
  .div-leg-stops-dot-div {
    /* background-color: rgb(194, 194, 194); */
    display: flex;
    height: 7px;
    width: 100%;
    position: absolute;
    justify-content: space-between;
    margin-left: 5px;
    top: -3px;
  }
  
  .div-leg-stops-dot {
    display: flex;
    width: 7px;
    height: 7px;
    background-color: #F2994A;
    border-radius: 50%;
    /* margin-left: 4px; */
  }
  
  
  .div-leg-data-up-right{
    /* background-color: #22a30e; */
    display: flex;
    flex: 0.21;
    height: 100%;
    align-items: flex-start;
    justify-content: flex-end;
  }
  
  .div-leg-data-down-left {
    /* background-color: #0e85a3; */
    display: flex;
    flex: 0.21;
    height: 100%;
    align-items: center;
    justify-content: flex-end;
    /* justify-content: center;
    align-items: center;
    flex-direction: column; */
  }
    
  .text-airport {
    color: #000000;
    font-size: 13px;
     font-family: 'Nunito-Regular';
  }
  
  .div-leg-data-down {
    /* background-color: #a3290e; */
    display: flex;
    flex: 0.2;
    width: 100%;
    /* justify-content: center;
    align-items: center;
    flex-direction: column; */
  }
  
  .div-leg-data-down-mid {
    /* background-color: #97a30e; */
    display: flex;
    flex: 0.5;
    height: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  
  .text-direct-stop {
    color:rgb(0, 32, 135);
    font-size: 12px;
     font-family: 'Nunito-Regular';
  }
  
  
  .div-leg-data-down-right {
    /* background-color: #0e85a3; */
    display: flex;
    flex: 0.21;
    height: 100%;
    align-items: center;
    justify-content: flex-start;
    /* justify-content: center;
    align-items: center;
    flex-direction: column; */
  }
  
  
  .button-flight-offer {
    display: flex;
    width: 50%;
    height: 50px; 
    color: aliceblue;
    background-color: #ffcc00;
    cursor: pointer;
    border-top-right-radius: 13px;
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 0px;
    border: none;
    justify-content: space-between;
    align-items: center;
    margin-top: 0px;
    margin-left: 0px;
    padding-left: 20px;
    padding-right: 10px;
  }
  
  
  .button-text-offer{
    color: #414141;
    font-size: 13px;
    font-family: 'Nunito-Bold';
  }

  .text-price-currency {
    color: #FFFFFF;
    font-size: 12px;
    font-family: 'Nunito-Regular';
    margin-top: 2px;
    margin-right: 3px;
  }

  .text-price {
    color: #FFFFFF;
    font-size: 18px;
    font-family: 'Nunito-Bold';
    font-weight: bold;
  }

  .text-price-cents {
    color: #FFFFFF;
    font-size: 12px;
    font-family: 'Nunito-Bold';
    font-weight: bold;
    margin-top: 2px;
  }
  
  
  .text-agent {
    color: rgb(46, 46, 46);
    font-size: 15px;
    font-family: 'Nunito-Bold';
    margin-bottom: 5px;
    /* font-weight: bold; */
  }
  
  
  .div-flight-details {
    /* background-color: #1a0ea3; */
    /* border-top: 1px solid #b3b3b385; */
    display: flex;
    width: 100%;
    height: auto;
    flex-direction: column;
    align-items: center;
    padding-top: 0px;
    padding-bottom: 20px;
  }
  
  .div-flight-details-item {
    /* background-color:  #e80ed6; */
    display: flex;
    width: 90%;
    height: auto;
    flex-direction: column;
    border-radius: 10px;
    border: 1px solid #b3b3b385;
    padding-bottom: 20px;

  }
  
  .header-details-item {
    /* background-color:  #e80ed6; */
    display: flex;
    width: 100%;
    height: fit-content;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #b3b3b385;
  }
  
  .header-details-date {
    color: rgb(46, 46, 46);
    font-size: 12px;
    font-family: 'Nunito-Bold';
    font-weight: bold;
    margin-left: 20px;
  }
  
  .header-details-duration{
    color: rgb(46, 46, 46);
    font-size: 12px;
    font-family: 'Nunito-Bold';
    font-weight: bold;
    margin-right: 20px;
  }
  
  .div-body-details {
    /* background-color:  #94e80e; */
    display: flex;
    width: 100%;
    height: auto;
    flex-direction: column;
    align-items: center;
    align-self: center;
    margin-top: 0px;
  }
  
  .div-body-details-item {
    /* background-color:  #cb0ee8; */
    display: flex;
    width: 90%;
    height: fit-content;
    flex-direction: column;
    align-items: center;
    align-self: center;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  
  .div-details-item-up {
    /* background-color:  #0e20e8; */
    display: flex;
    width: 100%;
    height: 50px;
    flex-direction: row;
    align-items: center;
  }
  
  
  
  .image-airline-item-up {
    height: 18px;
    object-fit: fill; 
  }
  
  
  .div-details-item-down {
    /* background-color:  #9f0ee8; */
    display: flex;
    flex-direction: row;
    width: 100%;
    height: fit-content;
    /* align-items: center; */
    /* margin-top: 10px; */
    /* align-items: center; */
  
  }
  
  .div-line-conection {
    /* background-color:  #0eb5e8; */
    display: flex;
    flex-direction: column;
    height: 96%;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    /* align-items: center; */
  }
  
  
  .dot-conection {
    height: 6px;
    aspect-ratio: 1/1;
    /* border: 2px solid #a9a9a9; */
    border-radius: 50%;
    background-color: #F2994A;
  } 
  
  .line-conection {
    width: 1px;
    height: 100%;
  }
  
  .div-conections {
    /* background-color:  #0ee8c7; */
    display: flex;
    flex-direction: column;
    /* flex:1; */
    height:fit-content;
    justify-content: space-between;
    /* align-items: center; */
    /* justify-items: center;
    justify-content: space-between; */
  }

  .div-conections-top-content {
    display: flex;
    flex-direction: row;
    height: fit-content;
    /* margin-bottom: 5px; */
  }
  
  
  .text-time-conections {
    color: rgb(45, 45, 45);
    font-size: 12px;
    font-family: 'Nunito-Bold';
    /* white-space: nowrap;     
    overflow: hidden;         */

  }

  .text-place-conections {
    color: rgb(45, 45, 45);
    font-size: 12px;
    font-family: 'Nunito-Regular';
    /* white-space: nowrap;      
    overflow: hidden;         
    text-overflow: ellipsis;  
    width: 100%;           */
  }
  
  .text-duration-connection{
    color: #828282;
    font-size: 12px;
    font-family: 'Nunito-Regular';
    margin-top: 5px;
    margin-bottom: 5px;
  }
  
  .div-arrow-conections {
    /* background-color:  #190ee8; */
    display: flex;
    flex-direction: column;
    flex:0.08  ;
    height: 100%;
    align-items: center;
    justify-items: center;
    justify-content: space-between;
  }
  
  
  .icon-arrow-conections {
    font-size: 24px;
  }
  
  
  .div-airports-names-stops {
    /* background-color:  #e8e80e; */
    display: flex;
    flex-direction: column;
    flex:0.8;
    height: 100%;
    justify-content: space-between;
    /* align-items: center; */
    /* align-items: center; */
  }
  
  .text-airport-name {
    color: rgb(45, 45, 45);
    font-size: 12px;
     font-family: 'Nunito-Regular';
    margin-left: 0px;
  }
  
  /* .div-stop-division {
    display: flex;
    width: 90%;
    height: 40px;
    background-color: #eaeaea;
    border-radius: 10px;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
  } */

  .div-stop-division {
    /* background-color: #eaeaea; */
    display: flex;
    width: 100%;
    border-radius: 10px;
    align-items: center;
    margin-top: 15px;
  }
  
  
  .icon-clock-time-stopped{
    font-size: 18px;
    margin-right: 10px;
  }
  
  .dot-clock-time-stopped{
    display: flex;
    height: 5px;
    aspect-ratio: 1/1;
    background-color: #EB5757;
    border-radius: 50%;
    align-self: center;
    margin-right: 8px;
  }

  .text-time-stopped{
    display: flex;
    color: #EB5757;
    font-size: 12px;
    font-family: 'Nunito-Regular';
    margin-left: 20px;
  }
  
  .div-sellers-details-item {
    /* background-color:  #e80ed6; */
    display: flex;
    width: 90%;
    height: auto;
    flex-direction: column;
    border-radius: 10px;
    align-items: center; 
    justify-content: center;
    
  }
  
  .div-seller {
    /* background-color: rgb(2, 131, 88); */
    display: flex;  
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  
  .image-seller {
    height: 20px;
    overflow: hidden;
    object-fit: contain;
  }
  
  .div-left-seller {
    background-color: #FFFFFF;
    display: flex;
    flex-direction: row;
    width: 50%;
    height: 100%;
    align-items: center;
    border: 1.5px solid #219653;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    justify-content: center;
    gap: 20px;

  }
  

  .div-right-seller {
    background-color: #219653;
    border: 1.5px solid #219653;
    display: flex;
    flex-direction: row;
    width: 50%;
    height: 100%;
    align-items: center;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  
  .text-price-seller {
    display: flex;
    margin-right: 10px;
    font-family: 'Nunito-Bold';  
    font-size: 12px;
  }
  
  .button-seller {
    display: flex;
    width: 65%;
    min-width: 60px;
    object-fit: fill; 
    cursor: pointer;
    color: #fff;
    background-color: #2ecc71;
    border-radius: 5px;
    border: none;
    justify-content: center;
    align-items: center;
    font-family: 'Nunito-Bold';  
    font-weight: bolder;
    font-size: 2px;

    &:hover{
      background-color: #219c54;
    }
  }
  
  .div-separator-seller{
    display: flex;
    width: 100%;
    height: 1px;
    background-color: #b3b3b385;
  }
  
  .icon-flight-card {
    font-size: 25px;
    color: rgb(0, 0, 0);
  }

  .text-duration{
    vertical-align: flex-end;
    color: rgb(45, 45, 45);
    font-size: 11px;
     font-family: 'Nunito-Regular';
  }

  .div-text-price {
    display: flex;
    width: 50%;
    height: 100%;
    background-color: #00688D;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    border-top-right-radius: 0px;
    align-items: center;
    justify-content: center;
  }

  .div-right-arrow {
    display: flex;
    margin-top: 1px;
    margin-left: 0px;
  }
  
  .div-image-flight-number {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .div-header-details-item-mid {
    /* background-color: #0400ff; */
    display: flex;
    flex-direction: column;
    width: 42.5%;
    height: 100%;
    justify-content: center;
  }
  
  .div-header-details-item-right {
    /* background-color: #00ff44; */
    display: flex;
    flex-direction: column;
    width: 42.5%;
    height: 100%;
    justify-content: center;
  
  }

  .div-sellers-details {
    /* background-color:  #94e80e; */
    display: flex;
    width: 100%;
    height: auto;
    flex-direction: column;
    align-items: center;
    align-self: center;
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .div-text-price-content-sellers {
    /* background-color: #1a008d; */
    display: flex;
    width: fit-content;
    justify-content: center;
    margin-right: 0px;
  }

  .div-message-before-flight-details {
    /* background-color: #0e6a7a; */
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    justify-self: center;
    width: 90%;
    margin-top: 0px;
  }

  .div-image-sellers {
    /* background-color: #138d00;   */
    display: flex;
    /* width: 50px; */
    width: 25%;
    /* max-width: 50px; */
    justify-content: flex-end;
  }

  .dashed-line {
    border-top: 1px dashed #AAAAAA;
    width: 90%;
    margin: 10px 0;
  }
}

